<template>
  <HospitalHeader></HospitalHeader>
  <div align="center">
    <div
      class="imprint"
      align="start"
      style="font-family: Verdana, Geneva, Tahoma, sans-serif"
    >
      <h1 class="mt-10" style="font-size: xxx-large">
        {{ $t('about-us.about') }}
      </h1>
      <h3 class="mt-2 mb-1">{{ $t('about-us.vision') }}</h3>
      <div>
        <p v-html="formattedFirstLine"></p>
        <p v-html="formattedSecondLine"></p>

        <p v-html="formattedThirdLine"></p>

        <p class="mt-2" style="font-weight: bold">
          {{ $t('about-us.fourth-line') }}
        </p>

        <h3 class="mt-2 mb-1">Team</h3>
        <p>{{ $t('about-us.team') }}</p>
      </div>

      <br />
    </div>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script setup lang="ts">
import FooterComponent from '@/components/Footer/FooterComponent.vue'

import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useMeta } from 'vue-meta'
import HospitalHeader from '@/components/Hospitals/Headers/HospitalHeader.vue'
FooterComponent
const { t } = useI18n()
useMeta(
  computed(() => ({
    title: t('about-us'),
  }))
)
const formattedFirstLine = computed(() => {
  return t('about-us.first-line').replace('CIRCLE', '<strong>CIRCLE</strong>')
})
const formattedSecondLine = computed(() => {
  return t('about-us.second-line')
    .replace('Medcircle', '<strong>Medcircle</strong>')
    .replace(' Medcircle', '<strong>Medcircle</strong>')
})
const formattedThirdLine = computed(() => {
  return t('about-us.third-line').replace(
    'Medcircle',
    '<strong>Medcircle</strong>'
  )
})
</script>

<style scoped>
.imprint {
  width: 70%;
}
@media screen and (max-width: 800px) {
  .imprint {
    width: 90%;
  }
}
</style>
