import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  align: "center",
  class: "mt-5"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "mb-10"
}
const _hoisted_4 = { style: {"width":"70px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('doctor.choose-time')), 1),
    _createVNode(_component_v_divider, { class: "mt-4" }),
    _createVNode(_component_v_card, {
      "max-width": "500",
      class: "mt-10 mb-10",
      style: {"border-radius":"5%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { align: "center" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_tooltip, { location: "top" }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_switch, _mergeProps(props, {
                    color: "primary",
                    modelValue: _ctx.activeSearch,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeSearch) = $event)),
                    label: _ctx.$t('doctor.calender')
                  }), null, 16, ["modelValue", "label"])
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('doctor.calender-tip')), 1)
                ]),
                _: 1
              }),
              (_ctx.activeSearch)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_v_tooltip, { location: "top" }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_switch, _mergeProps(props, {
                          modelValue: _ctx.lectureFreeSearch,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lectureFreeSearch) = $event)),
                          color: "primary",
                          label: _ctx.$t('doctor.semester-free'),
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.lectureFree()))
                        }), null, 16, ["modelValue", "label"])
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('doctor.semester-free-tip')), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.activeSearch)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.manualTime, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "mt-5 mb-5",
                      key: index
                    }, [
                      _createVNode(_component_v_row, { style: {"justify-content":"space-evenly"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_VueDatePicker, {
                            modelValue: _ctx.manualTime[index][0],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.manualTime[index][0]) = $event),
                            range: "",
                            "enable-time-picker": false,
                            "min-date": Date.now(),
                            teleport: true,
                            style: {"width":"60%"},
                            clearable: false,
                            format: "dd MMM yyyy"
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "min-date"]),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_v_text_field, {
                              density: "compact",
                              modelValue: _ctx.manualTime[index][1],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.manualTime[index][1]) = $event),
                              "hide-details": "",
                              "single-line": "",
                              type: "number",
                              rules: _ctx.minRule
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                          ]),
                          _createVNode(_component_v_btn, {
                            class: "mt-2",
                            density: "compact",
                            color: "red",
                            icon: "mdi-trash-can-outline",
                            onClick: ($event: any) => (
                  _ctx.manualTime = _ctx.manualTime
                    .slice(0, index)
                    .concat(_ctx.manualTime.slice(index + 1))
                )
                          }, null, 8, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128)),
                  _createVNode(_component_v_btn, {
                    density: "compact",
                    color: "primary",
                    icon: "mdi-plus",
                    rules: _ctx.minRule,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.manualTime.push([[], 1])))
                  }, null, 8, ["rules"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_footer, { style: {"display":"flex","width":"96%","justify-content":"end","background-color":"#f2f2f2"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createVNode(_component_v_btn, {
              class: "mr-10",
              rounded: "lg",
              color: "#3fb984",
              variant: "flat",
              size: "large",
              width: "150",
              disabled: _ctx.disableButton,
              loading: _ctx.isSaving,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.saveProfileData()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('save')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading"])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}