import { defineStore } from "pinia"
import apiClient from "./apiclients"
import { RequestResponse } from "./../types/index"
import { doctorAuthStore } from "./doctor-auth"
export const doctorActions = defineStore({
  id: "doctor-actions",
  state: () => ({
    docName: "",
    doctorProfileLink: "",
    studentTableTab: "",
  }),

  actions: {
    async getProfile(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem("docAccessToken")
      await apiClient
        .get("doctor/get-profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },

    async isTokenValid(): Promise<boolean> {
      let isValid = false
      let url = ""
      let name = ""
      const docAuth = doctorAuthStore()
      const token = localStorage.getItem("docAccessToken")
      if (token) {
        await apiClient
          .get("doctor/get-profile", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response) {
            isValid = response.data.success

            docAuth.isAuthenticated = true
            url = response.data.result.photo ? response.data.result.photo : ""
            name =
              response.data.result.title + " " + response.data.result.lastName
          })
          .catch(function (error) {
            docAuth.isAuthenticated = false
            localStorage.removeItem("docAccessToken")
            isValid = error.response.data.success
          })
        this.docName = name
        this.doctorProfileLink = url
      }

      return isValid
    },

    getDatesInString(data: Date): string {
      const year = data.getFullYear()
      const month = String(data.getMonth() + 1).padStart(2, "0")
      const day = String(data.getDate()).padStart(2, "0")

      return `${year}-${month}-${day}`
    },
    async uploadImage(formData: FormData): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem("docAccessToken") ?? ""

      await apiClient
        .post("common/upload-file?type=profile", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getUniversities(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      await apiClient
        .get("public/university-list")
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async updateProfile(doctorProfile: any): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem("docAccessToken") ?? ""

      await apiClient
        .post("doctor/update-profile", doctorProfile, {
          headers: {
            Authorization: `Bearer ${docToken}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getApplications(
      type: string,
      page: number,
      itemsPerPage: number
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem("docAccessToken") ?? ""

      await apiClient
        .post(
          "get-application",
          {
            page: page,
            pageSize: itemsPerPage,
            role: "",
            status: type,
            name: "",
            search: "",
          },
          {
            headers: {
              Authorization: `Bearer ${docToken}`,
            },
          }
        )
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getDashboard(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem("docAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${docToken}`,
      }

      await apiClient
        .get("doctor/dashboard", { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getSingleApplication(id: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem("docAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${docToken}`,
      }

      await apiClient
        .get(`application/${id}`, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async updateApplicationStatus(requestBody: {
      status: string
      comment: string
      id: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem("docAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${docToken}`,
        "Content-Type": "application/json",
      }

      await apiClient
        .put(`update-application-status`, requestBody, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getHospitalInvite(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem("docAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${docToken}`,
      }

      await apiClient
        .get(`doctor/invite-list`, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async acceptHospitalInvite(inviteId: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem("docAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${docToken}`,
      }

      await apiClient
        .get(`doctor/invite/accept/${inviteId}`, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async rejectHospitalInvite(inviteId: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem("docAccessToken") ?? ""

      const headers = {
        Authorization: `Bearer ${docToken}`,
      }

      await apiClient
        .get(`doctor/invite/reject/${inviteId}`, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
  },
})
