<template>
  <div class="bgimg" align="center">
    <v-row class="mt-0">
      <v-col cols="12">
        <div align="center">
          <v-img
            :src="require('../../assets/main-logo.png')"
            width="360"
          ></v-img>
        </div>
        <!-- <p class="sub-text text-center" style="font-weight: 400">
          {{ $t("landing-line") }}
        </p> -->
        <p class="sub-text text-center" style="font-weight: 400">
          {{ $t("landing-line") }}
          <span class="typed-text">{{ typeValue }}</span>
          <span class="blinking-cursor">|</span>
          <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
        </p>
      </v-col>
    </v-row>
    <div
      align="center"
      v-if="!canSearch"
      style="margin-bottom: 90px; margin-top: 50px"
    >
      <v-btn @click="canSearch = true" color="#3fb984" size="large">
        {{ $t("get-started") }}</v-btn
      >

      <!-- <v-btn class="ml-3" color="#25344d" size="large">How it works</v-btn> -->
    </div>
    <Transition name="slide-fade">
      <search-component
        v-if="canSearch"
        style="margin-bottom: 30px"
      ></search-component>
    </Transition>
    <v-divider></v-divider>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import { useI18n } from "vue-i18n"
import SearchComponent from "./Search/SearchComponent.vue"
export default defineComponent({
  components: { SearchComponent },
  name: "FirstComponent",

  setup() {
    const { t } = useI18n()
    const canSearch = ref(false)
    return {
      t,
      canSearch,
    }
  },
  data: () => {
    const { t } = useI18n()
    const entityList = computed(() => {
      return [t("doctors"), t("uni-hospital"), t("hospitals")]
    })
    return {
      typeValue: "",
      typeStatus: false,
      displayTextArray: entityList,
      typingSpeed: 100,
      erasingSpeed: 100,
      newTextDelay: 2000,
      displayTextArrayIndex: 0,
      charIndex: 0,
    }
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200)
  },
  methods: {
    typeText() {
      if (
        this.charIndex <
        this.displayTextArray[this.displayTextArrayIndex].length
      ) {
        if (!this.typeStatus) this.typeStatus = true
        this.typeValue += this.displayTextArray[
          this.displayTextArrayIndex
        ].charAt(this.charIndex)
        this.charIndex += 1
        setTimeout(this.typeText, this.typingSpeed)
      } else {
        this.typeStatus = false
        setTimeout(this.eraseText, this.newTextDelay)
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true
        this.typeValue = this.displayTextArray[
          this.displayTextArrayIndex
        ].substring(0, this.charIndex - 1)
        this.charIndex -= 1
        setTimeout(this.eraseText, this.erasingSpeed)
      } else {
        this.typeStatus = false
        this.displayTextArrayIndex += 1
        if (this.displayTextArrayIndex >= this.displayTextArray.length)
          this.displayTextArrayIndex = 0
        setTimeout(this.typeText, this.typingSpeed + 1000)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.bgimg {
  background-size: cover; /* Ensures the image covers the entire div without stretching */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
  background-image: url("../../assets/back.jpg");
  background-attachment: fixed;
  position: relative;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blinking-cursor {
  font-size: 2rem;
  color: #2c3e50;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
.sub-text {
  font-size: 20px;
  font-weight: 300;
}
.image {
  margin-top: 0px;
}

.content {
  overflow-x: hidden;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
