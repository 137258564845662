<template>
  <request-status :response="requestResponse" :isLoading="isLoading" />

  <v-sheet
    class="align-center justify-center text-center mx-auto"
    elevation="4"
    width="100%"
    rounded
  >
    <div style="height: 20px"></div>
    <div class="text-h5 font-weight-medium mb-3">
      {{ $t("welcome-back") }}
      {{ dashboardData?.name }}
    </div>

    <v-divider class="mb-4"></v-divider>

    <div align="center" v-if="!dashboardData?.isVerified">
      <v-alert
        align="start"
        style="width: 95%"
        :text="$t('verification-on-text')"
        :title="$t('verification-on')"
        type="info"
        density="compact"
      ></v-alert>
    </div>

    <div
      class="mt-3 mb-3"
      align="center"
      v-if="!dashboardData?.isProfileComplete"
      @click="$router.push('/doctor-profile')"
      style="cursor: pointer"
    >
      <v-alert
        align="start"
        style="width: 95%"
        :text="$t('profile-incomplete-text')"
        :title="$t('profile-incomplete')"
        type="warning"
        density="compact"
      ></v-alert>
    </div>

    <BriefOverview
      class="mb-10"
      :data="dashboardData"
      v-if="!isLoading"
      @view-application="$emit('view-application', $event)"
    />
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { DashboardData } from "@/types"
import { hospitalActions } from "@/stores/hospital-actions"
import BriefOverview from "./BriefOverview.vue"
export default defineComponent({
  components: { BriefOverview },
  name: "HospitalOverviewComponent",

  setup(props, { emit }) {
    const { t } = useI18n()
    const hospitalAction = hospitalActions()

    const isLoading = ref(true)
    const requestResponse = ref()
    const dashboardData = ref<DashboardData>()
    onMounted(async () => {
      getDashboard()
    })

    const getDashboard = async () => {
      const response = await hospitalAction.getDashboard()
      requestResponse.value = response
      if (response.success) {
        dashboardData.value = response.result as DashboardData
      }
      isLoading.value = false
    }

    return { isLoading, requestResponse, dashboardData }
  },
  methods: {},
})
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
}
</style>
