<template>
  <div ref="counterSection">
    <h1 style="margin-top: 100px; font-size: 48px" align="center">
      {{ $t("sofar") }}
    </h1>
    <p
      class="mt-7"
      align="center"
      style="font-size: large; font-weight: 400; width: 60%; margin: auto"
    >
      {{ $t("count-explanation") }}
    </p>
    <div class="flex mb-10 mt-10" align="center">
      <div class="flex-item" v-if="startCounter">
        <div
          class="mr-10 ml-5"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <p style="font-size: 80px; color: #8164eb; font-weight: 600">
            <count-up
              :startVal="0"
              :endVal="150"
              :duration="4"
              :options="options"
            ></count-up>
          </p>
          <p style="font-size: 30px; color: black; font-weight: 600">
            {{ t("header.applications") }}
          </p>
        </div>
      </div>
      <div class="flex-item" v-if="startCounter">
        <div
          class="mr-10 ml-5"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <p style="font-size: 80px; color: #00b1aa; font-weight: 600">
            <count-up
              :startVal="0"
              :endVal="100"
              :duration="4"
              :options="options"
            ></count-up>
          </p>
          <p style="font-size: 30px; color: black; font-weight: 600">
            {{ t("students") }}
          </p>
        </div>
      </div>
      <div class="flex-item" v-if="startCounter">
        <div
          class="mr-10 ml-5"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <p style="font-size: 80px; color: #f54265; font-weight: 600">
            <count-up
              :startVal="0"
              :endVal="50"
              :duration="4"
              :options="options"
            ></count-up>
          </p>
          <p style="font-size: 30px; color: black; font-weight: 600">
            {{ t("doctors") }}
          </p>
        </div>
      </div>
      <div class="flex-item" v-if="startCounter">
        <div
          class="mr-10 ml-5"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <p style="font-size: 80px; color: #f6c13d; font-weight: 600">
            <count-up
              :startVal="0"
              :endVal="1"
              :duration="4"
              :options="options"
            ></count-up>
          </p>
          <p style="font-size: 30px; color: black; font-weight: 600">
            {{ t("hospitals") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <div class="flex-item">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              class="ml-5 mr-5 mb-5"
              align="center"
              min-width="420"
              :elevation="isHovering ? 16 : 6"
              v-bind="props"
            >
              <v-row class="mt-5 mb-10" align="center">
                <v-col cols="5">
                  <v-img
                    class="ml-10"
                    height="150px"
                    :src="require('../../../assets/hospital.png')"
                  ></v-img>
                </v-col>
                <v-col cols="7">
                  <div
                    class="mr-10 ml-5"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <p style="font-size: 80px; color: gray; font-weight: 600">
                      0+
                    </p>
                    <p style="font-size: 30px; color: gray; font-weight: 600">
                      {{ t('hospitals') }}
                    </p>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card>
          </template>
        </v-hover>
      </div> -->
<!-- <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              class="ml-5 mr-5 mb-5"
              align="center"
              :elevation="isHovering ? 16 : 6"
              v-bind="props"
            >
              <v-row class="mt-5 mb-10" align="center">
                <v-col cols="5">
                  <v-img
                    class="ml-10"
                    height="150px"
                    :src="require('../../../assets/doctor1.png')"
                  ></v-img>
                </v-col>
                <v-col cols="7">
                  <div
                    class="mr-10 ml-5"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <span
                      style="font-size: 80px; color: gray; font-weight: 600"
                    >
                      50+
                    </span>
                    <span
                      style="font-size: 30px; color: gray; font-weight: 600"
                    >
                      {{ t("doctors") }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card>
          </template>
        </v-hover>
      </div>
      <div class="flex-item">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              class="ml-5 mr-5 mb-5"
              align="center"
              :elevation="isHovering ? 16 : 6"
              v-bind="props"
            >
              <v-row class="mt-5 mb-10" align="center">
                <v-col cols="5">
                  <v-img
                    class="ml-10"
                    height="150px"
                    :src="require('../../../assets/graduated.png')"
                  ></v-img>
                </v-col>
                <v-col cols="7">
                  <div
                    class="mr-10 ml-5"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <span
                      style="font-size: 80px; color: gray; font-weight: 600"
                    >
                      100+
                    </span>
                    <span
                      style="font-size: 30px; color: gray; font-weight: 600"
                    >
                      {{ t("students") }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card>
          </template>
        </v-hover> -->

<script lang="ts">
import { defineComponent, ref } from "vue"
import { useI18n } from "vue-i18n"
import CountUp from "vue-countup-v3"
export default defineComponent({
  name: "CounterComponent",
  components: { CountUp },
  data() {
    const { t } = useI18n()
    const startCounter = ref(false)
    return {
      t,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "+",
      },
      startCounter,
    }
  },
  methods: {
    observeIntersection(entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.startCounter = true
        }
      })
    },
  },
  mounted() {
    const observer = new IntersectionObserver(this.observeIntersection, {
      threshold: 0.1, // 10% of the element should be visible
    })
    const counterSection = this.$refs.counterSection as HTMLElement
    if (counterSection) {
      observer.observe(counterSection)
    }
  },
  beforeUnmount() {
    if (this.$refs.counterSection) {
      const observer = new IntersectionObserver(this.observeIntersection)
      observer.disconnect()
    }
  },
})
</script>
<style>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
}
</style>
