<template>
  <DoctorHeader :val="isLoading"></DoctorHeader>
  <request-status :response="responseStatus" :isLoading="isLoading" />
  <div align="center" v-if="!isLoading">
    <v-card>
      <v-layout>
        <v-navigation-drawer
          :expand-on-hover="smallWindow"
          :rail="smallWindow"
          :permanent="!smallWindow"
          mobile-breakpoint="xs"
          width="260"
          height="100vh"
        >
          <v-list align="start">
            <v-list-item
              :prepend-avatar="
                docAction.doctorProfileLink !== ''
                  ? docAction.doctorProfileLink
                  : require('../../../assets/blank.png')
              "
              :title="name"
            ></v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list density="compact" nav align="start">
            <v-list-item
              prepend-icon="mdi-account"
              :title="$t('general-info')"
              :class="{
                'v-list-item--active':
                  selectedItem === 'Doctor General Information',
              }"
              @click="navigateTo('Doctor General Information')"
            >
            </v-list-item>

            <v-list-item
              prepend-icon="mdi-hospital"
              :title="$t('doctor.interns')"
              :class="{
                'v-list-item--active':
                  selectedItem === 'Internship Information',
              }"
              @click="navigateTo('Internship Information')"
            ></v-list-item>

            <v-list-item
              prepend-icon="mdi-calendar-range"
              :title="$t('doctor.time')"
              :class="{ 'v-list-item--active': selectedItem === 'Time Table' }"
              @click="navigateTo('Time Table')"
            ></v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main>
          <router-view />
        </v-main>
      </v-layout>
    </v-card>
  </div>
  <FooterComponent class="mb-15"></FooterComponent>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, onBeforeUnmount, ref, watch } from "vue"
import { DoctorProfile, Universities } from "@/types"
import DoctorHeader from "../Headers/DoctorHeader.vue"
import router from "@/router"
import { onMounted } from "vue"
import { doctorActions } from "@/stores/doctor-actions"

import FooterComponent from "@/components/Footer/FooterComponent.vue"
import { useRoute } from "vue-router"

const { t } = useI18n()

const docAction = doctorActions()
const isLoading = ref(false)
const responseStatus = ref({})
const val = ref(true)
const selectedItem = ref<string>("info")

const route = useRoute()
const smallWindow = ref(window.innerWidth < 600)

onMounted(async () => {
  navigateTo((routeName.value as string) ?? "Doctor General Information")
})

const routeName = computed(() => {
  return route.name
})

watch([routeName], () => {
  selectedItem.value =
    (routeName.value as string) ?? "Doctor General Information"
})

onMounted(() => {
  window.addEventListener("resize", updateWindowSize)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWindowSize)
})

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

const name = computed(() => {
  return `${docAction.docName}`
})

const navigateTo = (item: string) => {
  selectedItem.value = item
  router.push({ name: item })
}
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.custom-subtitle {
  font-size: large;
}
</style>
