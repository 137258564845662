<template>
  <div>
    <h3 align="center" class="mt-5">
      {{ $t('doctor.choose-time') }}
    </h3>
    <v-divider class="mt-4"></v-divider>
    <v-card max-width="500" class="mt-10 mb-10" style="border-radius: 5%">
      <v-col align="center">
        <div>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-switch
                v-bind="props"
                color="primary"
                v-model="activeSearch"
                :label="$t('doctor.calender')"
              ></v-switch>
            </template>
            {{ $t('doctor.calender-tip') }}
          </v-tooltip>
          <div v-if="activeSearch">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-switch
                  v-bind="props"
                  v-model="lectureFreeSearch"
                  color="primary"
                  :label="$t('doctor.semester-free')"
                  @click="lectureFree()"
                ></v-switch>
              </template>
              {{ $t('doctor.semester-free-tip') }}
            </v-tooltip>
          </div>
        </div>

        <div class="mb-10" v-if="activeSearch">
          <div
            class="mt-5 mb-5"
            v-for="(item, index) in manualTime"
            :key="index"
          >
            <v-row style="justify-content: space-evenly">
              <VueDatePicker
                v-model="manualTime[index][0]"
                range
                :enable-time-picker="false"
                :min-date="Date.now()"
                :teleport="true"
                style="width: 60%"
                :clearable="false"
                format="dd MMM yyyy"
              />
              <div style="width: 70px">
                <v-text-field
                  density="compact"
                  v-model="manualTime[index][1]"
                  hide-details
                  single-line
                  type="number"
                  :rules="minRule"
                />
              </div>
              <v-btn
                class="mt-2"
                density="compact"
                color="red"
                icon="mdi-trash-can-outline"
                @click="
                  manualTime = manualTime
                    .slice(0, index)
                    .concat(manualTime.slice(index + 1))
                "
              ></v-btn>
            </v-row>
          </div>
          <v-btn
            density="compact"
            color="primary"
            icon="mdi-plus"
            :rules="minRule"
            @click="manualTime.push([[], 1])"
          ></v-btn>
        </div>
      </v-col>
    </v-card>
    <v-footer
      style="
        display: flex;
        width: 96%;
        justify-content: end;
        background-color: #f2f2f2;
      "
    >
      <div>
        <div>
          <v-btn
            class="mr-10"
            rounded="lg"
            color="#3fb984"
            variant="flat"
            size="large"
            width="150"
            :disabled="disableButton"
            :loading="isSaving"
            @click="saveProfileData()"
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserActions } from '@/stores/user-actions'
import { ApplicantTime, DoctorProfile } from '@/types'
import { doctorActions } from '@/stores/doctor-actions'
import { computed } from 'vue'

export default defineComponent({
  name: 'TimeTableComponent',

  setup(props, { emit }) {
    const { t } = useI18n()
    const requestResponse = ref({})

    const isLoading = ref(false)
    const docAction = doctorActions()
    const doctor = ref<DoctorProfile>()
    const isSaving = ref(false)
    const activeSearch = ref(false)
    const manualTime = ref<any[][]>([])
    const modelDateArray = ref<object[][]>([])
    const lectureFreeSearch = ref(false)
    const manualSearch = ref(false)
    const applicants = ref<ApplicantTime[]>([])
    const lectureFreeTimes = ref([
      [[new Date('2024-07-20'), new Date('2024-10-13')], 1],
      [[new Date('2025-02-08'), new Date('2025-04-21')], 1],
      [[new Date('2025-07-26'), new Date('2025-10-12')], 1],
    ])
    const numberValue = ref(0)

    onMounted(async () => {
      await getProfile()
      applicants.value = doctor.value?.applicants ?? []
      activeSearch.value = doctor.value?.useCalendarSearch ?? false
      manualSearch.value = activeSearch.value
      buildTimeArray(applicants.value)
    })

    const getProfile = async () => {
      isLoading.value = true
      const response = await docAction.getProfile()
      requestResponse.value = response
      if (response.success) {
        doctor.value = response.result as DoctorProfile
      }
      isLoading.value = false
    }

    const disableButton = computed((): boolean => {
      return (
        manualTime.value[manualTime.value.length - 1]?.[0].length == 0 ||
        manualTime.value[manualTime.value.length - 1]?.[0]?.[0] == null ||
        manualTime.value[manualTime.value.length - 1]?.[0]?.[1] == null ||
        manualTime.value[manualTime.value.length - 1]?.[1] < 1
      )
    })

    const minRule = [
      (v: number) => !!v || t('required'),
      (v: number) => v > 0 || t('min-student-required'),
    ]

    const buildTimeArray = (array: ApplicantTime[]) => {
      array.forEach((item: ApplicantTime) => {
        const startDate = new Date(item.start)
        const endDate = new Date(item.end)
        manualTime.value.push([[startDate, endDate], item.applicants])
      })

      modelDateArray.value = manualTime.value
    }

    return {
      requestResponse,
      isLoading,
      modelDateArray,
      t,
      disableButton,
      lectureFreeTimes,
      manualTime,
      minRule,
      isSaving,
      activeSearch,
      lectureFreeSearch,
      manualSearch,
      numberValue,
    }
  },
  methods: {
    lectureFree() {
      this.lectureFreeSearch = !this.lectureFreeSearch
      if (this.lectureFreeSearch) {
        this.manualTime = this.lectureFreeTimes
      } else {
        this.manualTime = this.modelDateArray
      }
    },
    async saveProfileData(): Promise<void> {
      this.isSaving = true
      const docAction = doctorActions()
      var applicants: ApplicantTime[] = []

      this.manualTime.forEach((val) => {
        const data: ApplicantTime = {
          start: docAction.getDatesInString(val[0][0] as Date),
          end: docAction.getDatesInString(val[0][1] as Date),
          applicants: parseInt(val[1].toString()),
        }
        applicants.push(data)
      })

      const data = {
        useCalendarSearch: this.activeSearch,
        applicants: applicants,
      }

      const response = await docAction.updateProfile(data)
      this.requestResponse = response

      if (response.success) {
        this.$toast.success(this.$t('profile-upload-successful'), {
          position: 'bottom-left',
        })
      } else {
        this.$toast.error(this.$t('profile-not-upload-successful'), {
          position: 'bottom-left',
        })
      }

      this.isSaving = false
      this.$emit('profile-saved', 'time-table')
    },
  },
})
</script>

<style scoped></style>
