<template>
  <HeaderComponent></HeaderComponent>

  <request-status :response="responseStatus" :isLoading="isLoading" />

  <div align="center" v-if="!isLoading && doctor">
    <v-row class="mt-5">
      <v-col v-if="!smallWindow" cols="3"></v-col>
      <v-col>
        <v-card max-width="700">
          <v-row class="mt-2" justify="space-between">
            <v-col class="mt-1" cols="8">
              <v-card-title class="custom-title">{{ doctorName }}</v-card-title>

              <p align="center">{{ doctorSpecialty }}</p>
              <v-hover>
                <template v-slot:default="{ isHovering, props }">
                  <v-card-subtitle
                    class="mt-2"
                    @click="openDocLocation"
                    v-bind="props"
                    style="cursor: pointer"
                  >
                    <v-icon
                      icon="mdi-map-marker"
                      :color="isHovering ? 'primary' : ''"
                    ></v-icon>
                    {{ doctorAddress }}</v-card-subtitle
                  >
                </template>
              </v-hover>

              <div class="flex mt-4">
                <v-card-subtitle>
                  <v-img
                    width="60"
                    style="border-radius: 10%"
                    :src="doctor.universityLogo"
                    ><template v-slot:placeholder>
                      <div
                        class="d-flex align-center justify-center fill-height"
                      >
                        <v-progress-circular
                          color="grey-lighten-4"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </template> </v-img
                  >{{ doctor.university }}
                </v-card-subtitle>

                <SubscribeComponent
                  :photo="doctor.photo"
                  :name="`${doctor.title} ${doctor.lastName}`"
                  @subscribed="subscribed($event)"
                />
                <div align="center">
                  <v-btn
                    :disabled="!doctor.linkedInLink"
                    class="ml-5 mt-4"
                    icon="mdi-web"
                    size="small"
                    color="#3fb984"
                    @click="toDoctorLink"
                  ></v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              <v-img
                width="150"
                style="border-radius: 50%"
                :src="doctor.photo ?? require('../../assets/profile_def.png')"
              ></v-img>
            </v-col>
          </v-row>

          <v-list lines="one" class="mt-5" align="start">
            <v-list-item>
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-star"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div style="font-size: large; font-weight: 500">
                  {{ $t("doctor.famulatur") }}
                </div>
              </template>
              <div style="font-size: small" justify-start>
                {{ doctor.selectReason }}
              </div></v-list-item
            >
          </v-list>
          <v-list lines="one" align="start">
            <v-list-item>
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-clipboard-list"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div style="font-size: large; font-weight: 500">
                  {{ $t("doctor.requirement") }}
                </div>
              </template>
              <div style="font-size: small" justify-start>
                {{ doctor.requirementsForStudent }}
              </div>
            </v-list-item>
          </v-list>

          <v-list lines="one" align="start">
            <v-list-item>
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-currency-eur"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div style="font-size: large; font-weight: 500">
                  {{ $t("doctor.financial-help") }}
                </div>
              </template>
              <div style="font-size: small" justify-start>
                {{ doctor.financialDescription }}
              </div>
            </v-list-item>
          </v-list>
          <v-list lines="one" align="start">
            <v-list-item>
              <template v-slot:prepend>
                <v-avatar color="blue-darken-2">
                  <v-icon icon="mdi-home-city"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div style="font-size: large; font-weight: 500">
                  {{ $t("doctor.area") }}
                </div>
              </template>
              <div style="font-size: small" justify-start>
                {{ doctor.areaDescription }}
              </div>
            </v-list-item>
          </v-list>
          <v-col cols="auto">
            <v-btn
              size="large"
              color="#3fb984"
              :disabled="doctor.applied"
              @click="apply"
            >
              {{ $t("apply") }}</v-btn
            >
          </v-col>
        </v-card>
      </v-col>
      <v-col v-if="!smallWindow" cols="3">
        <student-comment />
      </v-col>
    </v-row>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, defineProps, onBeforeUnmount, ref } from "vue"
import { DoctorProfile, RequestResponse } from "@/types"
import HeaderComponent from "../Header/HeaderComponent.vue"
import router from "@/router"
import { onMounted } from "vue"
import { useRoute } from "vue-router"
import { useUserActions } from "@/stores/user-actions"
import StudentComment from "./StudentComments/StudentComment.vue"
import FooterComponent from "../Footer/FooterComponent.vue"
import SubscribeComponent from "./Subscribe/SubscribeComponent.vue"
import { useToast } from "vue-toast-notification"
import { useMeta } from "vue-meta"

const { t } = useI18n()
const route = useRoute()
const id = ref()
const userAction = useUserActions()
const isLoading = ref(true)
const doctor = ref<DoctorProfile | undefined>(undefined)
const responseStatus = ref({})
const smallWindow = ref(window.innerWidth < 1000)
const $toast = useToast()
onMounted(() => {
  id.value = route.params.id
  localStorage.removeItem("docId")
  getDoctorProfile()
  window.addEventListener("resize", updateWindowSize)
})

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWindowSize)
})

const openDocLocation = (): void => {
  const address = doctor?.value?.clinicStreet.split(" ")

  window.open(
    `https://www.google.com/maps/search/${address?.[0] ?? ""}+${
      address?.[1] ?? ""
    }+${doctor?.value?.clinicLocation ?? ""}+${doctor?.value?.clinicLand}`,
    "_blank"
  )
}

const toDoctorLink = (): void => {
  const headerLink = doctor?.value?.linkedInLink.split(":", 1)
  const wwwLink = doctor?.value?.linkedInLink.split(".", 1)
  console.log(headerLink)
  if (headerLink?.[0] == "https") {
    window.open(doctor?.value?.linkedInLink, "_blank")
  } else if (wwwLink?.[0] == "www") {
    window.open("https://" + doctor?.value?.linkedInLink, "_blank")
  }
}

// const buildTimeArray = (totalTime: string[]) => {
//   let firstDate = new Date(totalTime[0])

//   totalTime.slice(1, -1).forEach((date, index) => {
//     availableDatesArray.value = [firstDate]

//     const thisDate = new Date(date)
//     const nextDate = new Date(totalTime[index + 2])

//     const differenceInDays = Math.abs(
//       (nextDate.getTime() - thisDate.getTime()) / (1000 * 3600 * 24)
//     )

//     if (differenceInDays > 1) {
//       availableDatesArray.value.push(thisDate)

//       twoDimensionalDateArray.value.push(availableDatesArray.value)

//       availableDatesArray.value = []
//       firstDate = nextDate
//     } else if (index == totalTime.length - 3) {
//       availableDatesArray.value.push(nextDate)

//       twoDimensionalDateArray.value.push(availableDatesArray.value)
//     }
//   })
//   modelDateArray.value = twoDimensionalDateArray.value
// }

const doctorName = computed(() => {
  return doctor.value
    ? `${doctor.value.title} ${doctor.value.firstName} ${
        doctor.value.lastName || ""
      }`
    : ""
})
const doctorSpecialty = computed(() => {
  return doctor.value?.specialty ?? ""
})

const doctorAddress = computed(() => {
  return `${doctor.value?.clinicStreet}, ${doctor.value?.clinicLocation}, ${doctor.value?.clinicLand}`
})

useMeta(
  computed(() => ({
    title: `${doctorName.value ?? ""} 
        ${doctor.value?.clinicLocation ?? "Medcircle"}
        ${doctorSpecialty.value ?? ""}`,
    description: `${t("meta.doctor-description")} ${doctorName.value} - ${
      doctorSpecialty.value
    } `,

    meta: [
      { name: "viewport", content: "user-scalable=no" },
      {
        property: "og:title",
        content:
          doctorName.value +
          " " +
          doctor.value?.clinicLocation +
          " " +
          doctorSpecialty.value,
      },
      { property: "og:image", content: doctor?.value?.photo },
      {
        property: "og:type",
        content: `website`,
      },
      {
        property: "og:description",
        content: `${t("meta.doctor-description")} ${doctorName.value} - ${
          doctorSpecialty.value
        } `,
      },
      {
        property: "og:url",
        content: `https://medcircle.eu/doctors/${id.value}`,
      },
      {
        property: "og:site_name",
        content: `medcircle`,
      },
    ],
  }))
)

const getDoctorProfile = async (): Promise<void> => {
  const response = await userAction.getSingleDoctor(id.value, {})
  responseStatus.value = response
  if (response.success) {
    doctor.value = response.result as DoctorProfile
  }
  isLoading.value = false
}

const apply = (): void => {
  localStorage.setItem("docId", id.value)
  router.push(`${id.value}/application`)
}

const subscribed = (event: any) => {
  responseStatus.value = event.val

  if (event.val.success) {
    if (event.subscribed) {
      $toast.success(t("subscribe-successful"), {
        position: "top-right",
      })
    }
  } else {
    $toast.error(t("subscribe-not-successful"), {
      position: "top-right",
    })
  }
}
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-content: center !important;
  justify-content: center;
}

.flex-item {
  flex: 1 0 40%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}
</style>
