<template>
  <v-dialog v-model="openList" max-width="450px">
    <v-sheet
      border="md"
      class="pa-6 text-black mx-auto"
      color="white"
      min-width="350px"
      style="border-radius: 7%"
    >
      <v-data-table
        style="margin-top: 9px"
        v-model:items-per-page="itemsPerPage"
        :headers="studentHeader"
        :items="usersList"
        ><template v-slot:[`item.photo`]="props">
          <div v-if="props.item.photo" align="center">
            <v-img
              :src="props.item.photo"
              alt="student Image"
              v-bind="props"
              class="image mb-2"
              referrerpolicy="no-referrer"
              rel="noreferrer"
              style="cursor: pointer"
              @click="router.push(`doctors/${props?.item?.doctorId}`)"
            />
          </div>
          <div v-else>
            <v-img
              v-bind="props"
              :src="require('../../../../assets/stud-team1.png')"
              alt="student Image"
              class="image mb-2"
              referrerpolicy="no-referrer"
              rel="noreferrer"
            />
          </div>
        </template>
        <template v-slot:bottom> </template>
      </v-data-table>
      <div class="mt-2" align="end">
        <v-btn color="error" @click="$emit('close')"> {{ $t('close') }}</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { defineProps, ref, onMounted, defineEmits, onBeforeUnmount } from 'vue'
import { watch } from 'vue'

const props = defineProps<{
  viewSeenList: boolean
  usersList: any
  studentHeader: any
}>()
const emit = defineEmits(['close'])
const { t } = useI18n()
const openList = ref(props.viewSeenList)
const itemsPerPage = ref(-1)
const smallWindow = ref(window.innerWidth < 1000)

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

onMounted(() => {
  window.addEventListener('resize', updateWindowSize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowSize)
})

watch(openList, () => {
  if (!openList.value) {
    emit('close')
  }
})
</script>

<style scoped>
.image {
  width: 50px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 10px;
  height: 50px;
  max-width: 50px;
  min-height: 50px;
}
</style>
