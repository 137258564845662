import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { ref: "counterSection" }
const _hoisted_2 = {
  style: {"margin-top":"100px","font-size":"48px"},
  align: "center"
}
const _hoisted_3 = {
  class: "mt-7",
  align: "center",
  style: {"font-size":"large","font-weight":"400","width":"60%","margin":"auto"}
}
const _hoisted_4 = {
  class: "flex mb-10 mt-10",
  align: "center"
}
const _hoisted_5 = {
  key: 0,
  class: "flex-item"
}
const _hoisted_6 = {
  class: "mr-10 ml-5",
  style: {"display":"flex","flex-direction":"column","align-items":"center"}
}
const _hoisted_7 = { style: {"font-size":"80px","color":"#8164eb","font-weight":"600"} }
const _hoisted_8 = { style: {"font-size":"30px","color":"black","font-weight":"600"} }
const _hoisted_9 = {
  key: 1,
  class: "flex-item"
}
const _hoisted_10 = {
  class: "mr-10 ml-5",
  style: {"display":"flex","flex-direction":"column","align-items":"center"}
}
const _hoisted_11 = { style: {"font-size":"80px","color":"#00b1aa","font-weight":"600"} }
const _hoisted_12 = { style: {"font-size":"30px","color":"black","font-weight":"600"} }
const _hoisted_13 = {
  key: 2,
  class: "flex-item"
}
const _hoisted_14 = {
  class: "mr-10 ml-5",
  style: {"display":"flex","flex-direction":"column","align-items":"center"}
}
const _hoisted_15 = { style: {"font-size":"80px","color":"#f54265","font-weight":"600"} }
const _hoisted_16 = { style: {"font-size":"30px","color":"black","font-weight":"600"} }
const _hoisted_17 = {
  key: 3,
  class: "flex-item"
}
const _hoisted_18 = {
  class: "mr-10 ml-5",
  style: {"display":"flex","flex-direction":"column","align-items":"center"}
}
const _hoisted_19 = { style: {"font-size":"80px","color":"#f6c13d","font-weight":"600"} }
const _hoisted_20 = { style: {"font-size":"30px","color":"black","font-weight":"600"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_count_up = _resolveComponent("count-up")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("sofar")), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("count-explanation")), 1),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.startCounter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, [
                _createVNode(_component_count_up, {
                  startVal: 0,
                  endVal: 150,
                  duration: 4,
                  options: _ctx.options
                }, null, 8, ["options"])
              ]),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t("header.applications")), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.startCounter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, [
                _createVNode(_component_count_up, {
                  startVal: 0,
                  endVal: 100,
                  duration: 4,
                  options: _ctx.options
                }, null, 8, ["options"])
              ]),
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t("students")), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.startCounter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("p", _hoisted_15, [
                _createVNode(_component_count_up, {
                  startVal: 0,
                  endVal: 50,
                  duration: 4,
                  options: _ctx.options
                }, null, 8, ["options"])
              ]),
              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.t("doctors")), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.startCounter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("p", _hoisted_19, [
                _createVNode(_component_count_up, {
                  startVal: 0,
                  endVal: 1,
                  duration: 4,
                  options: _ctx.options
                }, null, 8, ["options"])
              ]),
              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.t("hospitals")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 512))
}