<template>
  <div>
    <v-card class="ml-2" height="820" levation="5">
      <v-img
        v-bind="props"
        class="mt-2"
        :width="133"
        style="border-radius: 50%"
        cover
        :src="
          hospital?.photo != '' && hospital?.photo != undefined
            ? hospital?.photo
            : require('../../../../assets/hospital.png')
        "
      ></v-img>

      <h3 class="mt-3 mb-3">{{ hospital?.name }}</h3>
      <v-divider style="width: 80%"></v-divider>
      <div class="mt-2 mb-2">
        <v-row align="center" justify="space-evenly">
          <v-rating
            class="ml-2 mr-2"
            v-model="rating"
            active-color="blue"
            color="orange-lighten-1"
            readonly
          ></v-rating>

          <h4 class="">
            <a href="https://www.famulatur-ranking.de/" target="_blank">
              Famulatur Ranking</a
            >
          </h4>
        </v-row>
      </div>
      <v-divider style="width: 80%"></v-divider>

      <div style="width: 80%" align="start">
        <p class="mt-4" style="font-size: large; font-weight: 500">
          {{ $t("contact") }}
        </p>
        <div>
          <p class="mt-2">{{ $t("email") }}:</p>
          <h4 class="mb-2">
            <a style="color: black" :href="'mailto:' + hospital?.email">
              {{ hospital?.email }}</a
            >
          </h4>
        </div>

        <v-divider></v-divider>

        <p class="mt-2">{{ $t("phoneNumber") }}:</p>
        <h4 class="mb-2">
          <a style="color: black" href="tel:'`${phoneNumber}`">{{
            phoneNumber
          }}</a>
        </h4>

        <v-divider></v-divider>

        <p class="mt-2">{{ $t("address") }}:</p>

        <h4 class="mb-2">{{ address }}</h4>

        <v-divider></v-divider>

        <div style="height: 200px">
          <l-map ref="map" v-model:zoom="zoom" :center="[longitude, latitude]">
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
            ></l-tile-layer>
          </l-map>
        </div>
        <v-divider></v-divider>
        <p class="mt-2">{{ $t("hospital.departments") }}</p>
        <h4 class="mb-2">
          <v-chip-group column style="pointer-events: none">
            <v-chip>General Medicine </v-chip>

            <v-chip>Child Care</v-chip>

            <v-chip>Chirologies</v-chip>

            <v-chip>Old Care </v-chip>

            <v-chip>Child Care</v-chip>

            <v-chip>Chirologies</v-chip>
          </v-chip-group>
        </h4>
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, defineProps, ref } from "vue"
import { Hospital } from "@/types"
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet"

const { t } = useI18n()
const zoom = 10
const props = defineProps<{ hospital: Hospital | undefined }>()
const hospital = ref<Hospital | undefined>(props.hospital)
const rating = ref(4)

const address = computed((): string => {
  return `${hospital.value?.hospitalStreet}, ${hospital.value?.hospitalLocation}, ${hospital.value?.hospitalZip},  ${hospital.value?.hospitalState},  ${hospital.value?.hospitalLand}  `
})
const latitude = computed((): number => {
  return hospital.value?.location.coordinates[0] ?? 48.1351
})
const longitude = computed((): number => {
  return hospital.value?.location.coordinates[1] ?? 11.582
})

const phoneNumber = computed((): string => {
  return `${hospital.value?.phoneNo ?? "-"}`
})
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
</style>
