import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { style: {"background-color":"#e1f5fd"} }
const _hoisted_2 = {
  style: {"margin-top":"50px","font-size":"48px"},
  align: "center"
}
const _hoisted_3 = {
  align: "center",
  justify: "space-evenly",
  class: "flex"
}
const _hoisted_4 = { style: {"font-weight":"500"} }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_slide_group_item = _resolveComponent("v-slide-group-item")!
  const _component_v_slide_group = _resolveComponent("v-slide-group")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_divider, { class: "mb-10" }),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("the-answer")) + ": MEDCIRCLE ", 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_sheet, {
        class: "mx-auto",
        style: {"background-color":"#e1f5fd"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_slide_group, { "show-arrows": "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
                return (_openBlock(), _createBlock(_component_v_slide_group_item, {
                  key: card.bulletPoints?.length
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      class: _normalizeClass(["mx-auto ml-10 mt-10 mb-10", _ctx.smallWindow ? 'mr-5' : 'mr-10']),
                      width: _ctx.smallWindow ? 250 : 500,
                      elevation: "10",
                      style: {"border-radius":"5%"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_img, {
                          height: "200px",
                          src: _ctx.getImage(card.photo),
                          cover: ""
                        }, null, 8, ["src"]),
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(card.title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(card.cities, (city) => {
                          return (_openBlock(), _createBlock(_component_v_chip, {
                            class: "ml-3 mt-2",
                            key: city,
                            "prepend-icon": "mdi-map-marker"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(city), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128)),
                        _createVNode(_component_v_card_actions, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              color: "#3fb984",
                              text: "Explore"
                            }),
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              icon: _ctx.show ? 'mdi-chevron-up' : 'mdi-chevron-down',
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = !_ctx.show))
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_expand_transition, null, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("div", null, [
                              _createVNode(_component_v_divider),
                              _createVNode(_component_v_card_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(card.explanation), 1)
                                ]),
                                _: 2
                              }, 1024),
                              (card.subExplanation)
                                ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", _hoisted_4, _toDisplayString(card.subExplanation), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true),
                              (card.bulletPoints)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(card.bulletPoints, (point) => {
                                      return (_openBlock(), _createBlock(_component_v_card_text, { key: point }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_icon, {
                                            color: "#3fb984",
                                            icon: "mdi-check"
                                          }),
                                          _createTextVNode(" " + _toDisplayString(point), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (card.logo)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createVNode(_component_v_img, {
                                      height: "150px",
                                      src: _ctx.getImage(card.logo)
                                    }, null, 8, ["src"])
                                  ]))
                                : _createCommentVNode("", true)
                            ], 512), [
                              [_vShow, _ctx.show]
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["class", "width"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider, { style: {"margin-top":"100px"} })
    ])
  ]))
}