<template>
  <div justify="space-between">
    <div class="flex flex-wrap">
      <div class="flex-item ml-2">
        <div class="mt-3" align="start">
          {{ $t("you-apply") }}
        </div>
        <VueDatePicker
          class="mb-2"
          :enable-time-picker="false"
          v-model="startDate"
          format="dd MMM yyyy"
          density="compact"
          variant="outlined"
          :clearable="false"
          :label="$t('you-apply')"
          :min-date="today"
          @change="handleStartDateChange"
          :placeholder="$t('search.date-line')"
        />
      </div>
      <div class="flex-item ml-2">
        <div class="mt-3" align="start">
          {{ $t("choose-doctor") }}
          <span class="text-subtitle-2" style="color: gray"
            >( {{ $t("not-necessary") }} )</span
          >
        </div>

        <v-autocomplete
          v-model="selectedDoctor"
          density="compact"
          :placeholder="$t('doctor.doctor')"
          :items="availableDocs"
          :item-title="doctorName"
          :item-value="doctorValue"
          variant="outlined"
        >
          <template v-slot:item="{ props, item }">
            {{ item }}
            <v-list-item v-bind="props">
              <template v-slot:subtitle> {{ item.raw.department }} </template>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <v-progress-circular
      v-if="isNewDateLoading"
      color="green"
      indeterminate
    ></v-progress-circular>
    <v-alert
      v-if="!isHospitalAvailable && startDate !== null && !isNewDateLoading"
      class="ml-6 mr-6"
      color="#C51162"
      icon="mdi-cancel"
      theme="dark"
      border
    >
      {{ $t("no-availability") }}
    </v-alert>
  </div>

  <GeneralInformation
    :isApplying="true"
    :isHospitalApplying="true"
    :isDoctorAvailable="isHospitalAvailable"
    :hospitalId="hospital?._id"
    :doctorId="selectedDoctor?._id"
    :startDate="startDate"
    :endDate="endDate"
    :hasAlreadyApplied="false"
  ></GeneralInformation>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, defineProps, ref, watch } from "vue"
import { Doctor, Hospital } from "@/types"
import { onMounted } from "vue"
import moment from "moment"
import GeneralInformation from "@/components/StudentProfile/GeneralInformation/GeneralInformation.vue"
import { useUserActions } from "@/stores/user-actions"
const { t } = useI18n()

const props = defineProps<{ hospital: Hospital | undefined }>()
const hospital = ref<Hospital | undefined>(props.hospital)
const startDate = ref<Date | null>(null)
const endDate = ref<Date | null>(null)
const today = ref(new Date())
const isNewDateLoading = ref(false)
const userAction = useUserActions()
const isHospitalAvailable = ref(false)
const availableDocs = ref<Doctor[]>()
const selectedDoctor = ref<Doctor>()
onMounted(async () => {
  await getParams()
  await handleStartDateChange(startDate.value)
})

const getParams = async () => {
  const storedSearchParams = localStorage.getItem("searchParams")
  if (storedSearchParams) {
    const savedSearchParams = JSON.parse(storedSearchParams)
    startDate.value = savedSearchParams?.startDate
      ? moment(savedSearchParams?.startDate).locale("de").toDate()
      : null

    endDate.value = savedSearchParams?.endDate
      ? moment(savedSearchParams?.endDate).locale("de").toDate()
      : null
  }
}

async function handleStartDateChange(newDate: Date | null): Promise<void> {
  isNewDateLoading.value = true

  if (newDate) {
    startDate.value = newDate
    endDate.value = new Date(startDate.value)
    endDate.value.setDate(endDate.value.getDate() + 30)
    const response = await userAction.isHospitalAvailable(
      hospital.value?._id ?? "",
      userAction.getDatesInString(startDate.value),
      userAction.getDatesInString(endDate.value)
    )
    availableDocs.value = response.result?.docs as Doctor[]
    isHospitalAvailable.value = availableDocs.value.length > 0
  }
  isNewDateLoading.value = false
}

const doctorName = (d: Doctor): string => {
  return d?.title + " " + d?.firstName + " " + d?.lastName
}

const doctorValue = (d: Doctor): Doctor => {
  return d
}
watch(startDate, handleStartDateChange)
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}
</style>
