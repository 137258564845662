<template>
  <h2 class="mt-4" v-if="!isApplying">{{ $t("general-info") }}</h2>
  <v-divider class="mt-3" v-if="!isApplying"></v-divider>
  <request-status :response="linkResponse" :isLoading="isLoading" />
  <div>
    <div v-if="!isApplying && student" class="mb-10 mt-2">
      <div>
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-img
              v-bind="props"
              :width="163"
              style="border-radius: 50%"
              cover
              :src="
                photoUrl !== ''
                  ? photoUrl
                  : require('../../../assets/blank.png')
              "
              @click="onImageButtonClick"
            ></v-img>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onImageUpload"
            />
          </template>
          {{ $t("image-upload") }}
        </v-tooltip>
      </div>
      <div>
        <v-dialog v-model="showCropper">
          <v-sheet
            border="md"
            class="pa-6 text-black mx-auto"
            :width="smallWindow ? 320 : 600"
            color="white"
          >
            <h4 class="text-h5 font-weight-bold mb-4">
              <cropper
                class="cropper"
                :src="localImageUrl"
                :stencil-props="{
                  aspectRatio: 10 / 11,
                }"
                @change="change"
              />
              <div align="end" class="mt-3">
                <v-btn color="error" @click="showCropper = false">{{
                  $t("go-back")
                }}</v-btn>
                <v-btn class="ml-2" color="green" @click="saveImage">{{
                  $t("save")
                }}</v-btn>
              </div>
            </h4>
          </v-sheet>
        </v-dialog>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="flex-item" justify="start">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("gender") }}
        </div>

        <v-select
          class="ml-2"
          v-model="gender"
          density="compact"
          :placeholder="$t('gender')"
          prepend-inner-icon="mdi-pencil"
          :items="genders"
          variant="outlined"
          style="min-width: 300px"
        ></v-select>
      </div>

      <div class="flex-item" justify="start">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("first-name") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="firstName"
          density="compact"
          :placeholder="$t('first-name')"
          prepend-inner-icon="mdi-pencil"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>

      <div class="flex-item">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("last-name") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="lastName"
          density="compact"
          :placeholder="$t('last-name')"
          prepend-inner-icon="mdi-pencil"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>

      <div class="flex-item">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("email") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="email"
          density="compact"
          readonly
          :placeholder="$t('email')"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>

      <div class="flex-item ml-2">
        <div class="d-flex text-subtitle-1 text-medium-emphasis">
          {{ $t("birthday") }}
        </div>

        <VueDatePicker
          scrollable
          v-model="dateOfBirth"
          color="primary"
          :enable-time-picker="false"
          :max-date="today"
          :clearable="false"
          format="dd MMM yyyy"
          class="v-theme--light mb-2"
          style="min-width: 300px"
        />
      </div>
      <div class="flex-item">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("phoneNumber") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="phoneNumber"
          density="compact"
          :placeholder="$t('phoneNumber')"
          prepend-inner-icon="mdi-cellphone"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>
      <div class="flex-item">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("street") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="street"
          density="compact"
          :placeholder="$t('street')"
          prepend-inner-icon="mdi-map-marker"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>
      <div class="flex-item">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("post") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="postCode"
          density="compact"
          :placeholder="$t('post')"
          prepend-inner-icon="mdi-numeric"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>
      <div class="flex-item">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("city") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="city"
          density="compact"
          :placeholder="$t('city')"
          prepend-inner-icon="mdi-city"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>
      <div class="flex-item">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("state") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="state"
          density="compact"
          :placeholder="$t('state')"
          prepend-inner-icon="mdi-map-marker"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>
      <div class="flex-item">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("country") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="country"
          density="compact"
          :placeholder="$t('country')"
          prepend-inner-icon="mdi-earth"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>

      <div class="flex-item">
        <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
          {{ $t("university") }}
        </div>
        <v-text-field
          class="ml-2"
          v-model="university"
          density="compact"
          :placeholder="$t('university')"
          prepend-inner-icon="mdi-school"
          variant="outlined"
          style="min-width: 300px"
        ></v-text-field>
      </div>
    </div>
  </div>
  <div align="center" justify="space-between" class="flex mb-10">
    <div class="flex-item">
      <div class="mt-5">
        <v-btn
          rounded="lg"
          :color="cvUrl ? 'primary' : 'red'"
          variant="flat"
          size="large"
          width="180"
          prepend-icon="mdi-folder"
          :href="cvUrl ?? ''"
          target="_blank"
          class="mb-2"
          >{{ $t("resume") }}
        </v-btn>

        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              icon="mdi-folder"
              color="primary"
              class="ml-5 mb-2"
              :loading="isCVSelecting"
              @click="onCVButtonClick"
            ></v-btn>
            <input
              ref="uploaderCV"
              class="d-none"
              type="file"
              accept="application/pdf"
              @change="onCVUpload"
            />
          </template>
          {{ $t("upload") }}
        </v-tooltip>
      </div>
    </div>

    <div class="flex-item">
      <div class="mt-5">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              rounded="lg"
              :color="ibUrl ? 'primary' : 'red'"
              variant="flat"
              size="large"
              width="180"
              prepend-icon="mdi-folder"
              :href="ibUrl ?? ''"
              target="_blank"
              class="mb-2"
            >
              {{ $t("ib-short") }}</v-btn
            >
          </template>
          {{ $t("ib") }}
        </v-tooltip>
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              icon="mdi-folder"
              color="primary"
              class="ml-5 mb-2"
              :loading="isIBSelecting"
              @click="onIBButtonClick"
            ></v-btn>
            <input
              ref="uploaderIB"
              class="d-none"
              type="file"
              accept="application/pdf"
              @change="onIBUpload"
            />
          </template>
          {{ $t("upload") }}
        </v-tooltip>
      </div>
    </div>
    <div class="flex-item" v-if="isApplying">
      <div class="mt-5">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              rounded="lg"
              :color="motivationUrl ? 'primary' : 'red'"
              variant="flat"
              size="large"
              prepend-icon="mdi-folder"
              :href="motivationUrl"
              target="_blank"
              class="mb-2"
            >
              {{ $t("motivation") }}</v-btn
            >
          </template>
          {{ $t("tip-motivation") }}
        </v-tooltip>
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              icon="mdi-folder"
              color="primary"
              class="ml-5 mb-2"
              :loading="isMotivationSelecting"
              @click="onMLButtonClick"
            ></v-btn>
            <input
              ref="uploaderML"
              class="d-none"
              type="file"
              accept="application/pdf"
              @change="onMotivationUpload"
            />
          </template>
          {{ $t("upload") }}
        </v-tooltip>
      </div>
    </div>
    <div class="flex-item" v-if="!isApplying">
      <div class="mt-5">
        <div>
          <v-btn
            rounded="lg"
            color="#3fb984"
            variant="flat"
            size="large"
            width="180"
            :loading="isSaving"
            @click="saveProfileData()"
          >
            {{ $t("save") }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex-item" v-if="isApplying">
    <v-dialog v-model="dialog" max-width="400" persistent>
      <template v-slot:activator="{ props: activatorProps }">
        <div class="">
          <v-col cols="auto">
            <v-btn
              size="large"
              v-bind="activatorProps"
              color="#3fb984"
              :loading="dialog"
              :disabled="
                hasAlreadyApplied || !isDoctorAvailable || !infoCompleted
              "
              @click="dialog = true"
              >{{ $t("apply") }}</v-btn
            >
          </v-col>
        </div>
      </template>

      <v-card prepend-icon="mdi-rocket-launch" :title="$t('apply-title')">
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn @click="apply(false)">{{ $t("go-back") }}</v-btn>
          <v-btn @click="apply(true)">{{ $t("apply") }}</v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { RequestResponse, Student } from "@/types"
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useUserActions } from "@/stores/user-actions"
import { useToast } from "vue-toast-notification"
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"
import router from "@/router"
export default defineComponent({
  name: "GeneralInformation",
  components: {
    Cropper,
  },

  props: {
    isApplying: {
      type: Boolean,
    },
    startDate: {
      type: Date,
      default: null,
    },
    endDate: {
      type: Date,
      default: null,
    },
    hasAlreadyApplied: {
      type: Boolean,
      default: false,
    },
    isDoctorAvailable: {
      type: Boolean,
      default: false,
    },
    doctorId: {
      type: String,
    },
    hospitalId: {
      type: String,
    },

    isHospitalApplying: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n()
    const userAction = useUserActions()
    const $toast = useToast()
    const student = ref<Student>()
    const firstName = ref<string | undefined>(undefined)
    const lastName = ref<string | undefined>(undefined)
    const email = ref<string | undefined>(undefined)
    const gender = ref<string | undefined>(undefined)
    const dateOfBirth = ref<Date | null>(null)
    const today = ref(new Date())
    const street = ref<string | undefined>(undefined)
    const postCode = ref<string | undefined>(undefined)
    const city = ref<string | undefined>(undefined)
    const state = ref<string | undefined>(undefined)
    const country = ref<string | undefined>(undefined)
    const phoneNumber = ref<string | undefined>(undefined)
    const university = ref<string | undefined>(undefined)
    const cvUrl = ref<string | null>(null)
    const ibUrl = ref<string | null>(null)
    const motivationUrl = ref<string | undefined>(undefined)
    const cvSecretUrl = ref<string | undefined>(undefined)
    const ibSecretUrl = ref<string | undefined>(undefined)
    const motivationSecretUrl = ref<string | null>(null)
    const photoUrl = ref<string>("")
    const linkResponse = ref({})
    const isLoading = ref(false)
    const selectedFile = ref(null)
    const isSelecting = ref(false)
    const isCVSelecting = ref(false)
    const isIBSelecting = ref(false)
    const isSaving = ref(false)
    const isMotivationSelecting = ref(false)
    const applicationSuccessful = ref(false)
    const dialog = ref(false)
    const docId = ref("")
    const localImageUrl = ref("")
    const showCropper = ref(false)
    const canvas = ref()
    const smallWindow = ref(window.innerWidth < 1000)
    const token = ref(localStorage.getItem("accessToken") ?? "")
    onMounted(async () => {
      await getProfile()

      docId.value = props.doctorId ?? ""
      gender.value = student.value?.gender ?? "-"
      firstName.value = student.value?.firstName ?? undefined
      lastName.value = student.value?.lastName ?? undefined
      email.value = student.value?.email ?? undefined
      street.value = student.value?.street ?? undefined
      postCode.value = student.value?.zip ?? undefined
      city.value = student.value?.location ?? undefined
      state.value = student.value?.state ?? undefined
      country.value = student.value?.land ?? undefined
      phoneNumber.value = student.value?.phoneNo ?? undefined
      university.value = student.value?.university ?? undefined
      photoUrl.value = student.value?.profileUrl ?? ""
      ibSecretUrl.value = student.value?.immatriculationUrl
      cvSecretUrl.value = student.value?.cvUrl

      if (student.value?.cvUrl) {
        link(student.value?.cvUrl).then((val) => {
          cvUrl.value = val
        })
      }
      if (student.value?.immatriculationUrl) {
        link(student.value?.immatriculationUrl).then((val) => {
          ibUrl.value = val
        })
      }
      const rawDate = student.value?.dateOfBirth
      if (rawDate) {
        dateOfBirth.value = new Date(rawDate)
      }
    })

    const getProfile = async () => {
      isLoading.value = true
      if (token.value == "") {
        router.push("/login")
      }
      const response = await userAction.getStudentProfile(token.value)
      linkResponse.value = response
      if (response.success) {
        student.value = response.result as Student
      }
      isLoading.value = false
    }

    const link = async (link: string): Promise<string> => {
      isLoading.value = true
      const linkObject = {
        fileUri: link,
      }
      const response = await userAction.createPublicFile(
        linkObject,
        localStorage.getItem("accessToken") ?? ""
      )
      isLoading.value = false

      linkResponse.value = response

      if (typeof response.result === "string") {
        return response.result
      }
      return ""
    }

    const formattedDateOfBirth = computed(() => {
      return dateOfBirth.value ? dateOfBirth.value.toLocaleDateString() : ""
    })

    const genders = computed(() => {
      return ["-", t("female"), t("male")]
    })

    const infoCompleted = computed(() => {
      return (
        firstName.value != "" &&
        lastName.value != "" &&
        dateOfBirth.value != null &&
        street.value != "" &&
        postCode.value != "" &&
        city.value != "" &&
        state.value != "" &&
        country.value != "" &&
        university.value != "" &&
        motivationSecretUrl.value != null
      )
    })

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    return {
      firstName,
      lastName,
      email,
      gender,
      genders,
      dateOfBirth,
      formattedDateOfBirth,
      today,
      street,
      postCode,
      city,
      state,
      country,
      phoneNumber,
      university,
      linkResponse,
      isLoading,
      cvUrl,
      ibUrl,
      selectedFile,
      isCVSelecting,
      isIBSelecting,
      isSelecting,
      link,
      cvSecretUrl,
      ibSecretUrl,
      dialog,
      applicationSuccessful,
      photoUrl,
      infoCompleted,
      motivationUrl,
      motivationSecretUrl,
      isMotivationSelecting,
      isSaving,
      docId,
      $toast,
      smallWindow,
      localImageUrl,
      showCropper,
      canvas,
      student,
    }
  },
  methods: {
    onCVButtonClick() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      if (this.$refs.uploaderCV instanceof HTMLInputElement) {
        this.$refs.uploaderCV.click()
      }
    },
    onIBButtonClick() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      if (this.$refs.uploaderIB instanceof HTMLInputElement) {
        this.$refs.uploaderIB.click()
      }
    },
    onMLButtonClick() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      if (this.$refs.uploaderML instanceof HTMLInputElement) {
        this.$refs.uploaderML.click()
      }
    },
    onImageButtonClick() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      if (this.$refs.uploader instanceof HTMLInputElement) {
        this.$refs.uploader.click()
      }
    },
    change({ coordinates, canvas }: any) {
      this.canvas = canvas
    },
    saveImage() {
      const userAction = useUserActions()

      this.canvas.toBlob(async (blob: Blob) => {
        const formData = new FormData()
        formData.append("file", blob)

        const response = await userAction.uploadImage(formData)
        this.linkResponse = response
        if (response.success) {
          this.photoUrl = (response.result as string[])[0]
        }
        this.showCropper = false
      })
    },

    onImageUpload(e: any) {
      const image = e.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(image)

      reader.onload = async (e) => {
        this.localImageUrl = e?.target?.result as string
        this.showCropper = true
      }
    },

    async onCVUpload(e: any) {
      const userAction = useUserActions()
      this.isCVSelecting = true
      this.selectedFile = e.target.files[0]
      if (this.selectedFile) {
        const response = await userAction.uploadFile(this.selectedFile)
        this.linkResponse = response
        if (response.success) {
          this.cvSecretUrl = (response.result as string[])[0] ?? undefined

          if (typeof this.cvSecretUrl === "string") {
            this.link(this.cvSecretUrl).then((val) => {
              this.cvUrl = val
            })
          }

          this.$toast.success(this.$t("upload-successful"), {
            position: "top-right",
          })
        } else {
          this.$toast.error(this.$t("upload-successful"), {
            position: "top-right",
          })
        }
      }

      this.isCVSelecting = false
    },
    async onIBUpload(e: any) {
      const userAction = useUserActions()
      this.isIBSelecting = true
      this.selectedFile = e.target.files[0]
      if (this.selectedFile) {
        const response: RequestResponse = await userAction.uploadFile(
          this.selectedFile
        )
        this.linkResponse = response
        if (response.success) {
          this.ibSecretUrl = (response.result as string[])[0] ?? undefined

          if (typeof this.ibSecretUrl === "string") {
            this.link(this.ibSecretUrl).then((val) => {
              this.ibUrl = val
            })
          }

          this.$toast.success(this.$t("upload-successful"), {
            position: "top-right",
          })
        } else {
          this.$toast.error(this.$t("upload-successful"), {
            position: "top-right",
          })
        }
      }
      this.isIBSelecting = false
    },
    async onMotivationUpload(e: any) {
      const userAction = useUserActions()
      this.isMotivationSelecting = true
      this.selectedFile = e.target.files[0]
      if (this.selectedFile) {
        const response = await userAction.uploadFile(this.selectedFile)
        this.linkResponse = response
        if (response.success) {
          this.motivationSecretUrl =
            (response.result as string[])[0] ?? undefined
          if (typeof this.motivationSecretUrl === "string") {
            this.link(this.motivationSecretUrl).then((val) => {
              this.motivationUrl = val
            })
          }

          this.$toast.success(this.$t("upload-successful"), {
            position: "top-right",
          })
        } else {
          this.$toast.error(this.$t("upload-not-successful"), {
            position: "top-right",
          })
        }
      }
      this.isMotivationSelecting = false
    },

    async saveProfileData(): Promise<void> {
      this.isSaving = true
      const userAction = useUserActions()

      var data = {
        gender: this.gender,
        firstName: this.firstName,
        lastName: this.lastName,
        dateOfBirth: userAction.getDatesInString(
          this.dateOfBirth ?? new Date()
        ),
        street: this.street,
        zip: this.postCode,
        location: this.city,
        land: this.country,
        state: this.state,
        university: this.university,
        immatriculationUrl: this.ibSecretUrl,
        cvUrl: this.cvSecretUrl,
        profileUrl: this.photoUrl,
        phoneNo: this.phoneNumber,
      }

      const response = await userAction.updateStudentProfile(data)
      this.linkResponse = response

      if (response.success) {
        this.$toast.success(this.$t("profile-upload-successful"), {
          position: "top-right",
        })
        this.$emit("profile-saved")
      } else {
        this.$toast.error(this.$t("profile-not-upload-successful"), {
          position: "top-right",
        })
      }

      this.isSaving = false
    },
    async apply(apply: boolean): Promise<void> {
      if (apply) {
        this.isLoading = true
        const userAction = useUserActions()

        const data = {
          gender: this.gender,
          firstName: this.firstName,
          lastName: this.lastName,
          dateOfBirth: userAction.getDatesInString(
            this.dateOfBirth ?? new Date()
          ),
          street: this.street,
          zip: this.postCode,
          location: this.city,
          land: this.country,
          state: this.state,
          university: this.university,
          immatriculationUrl: this.ibSecretUrl,
          cvUrl: this.cvSecretUrl,
          profileUrl: this.photoUrl,
          phoneNo: this.phoneNumber,
        }

        const response = await userAction.updateStudentProfile(data)
        this.linkResponse = response

        if (response.success) {
          this.$toast.success(this.$t("profile-upload-successful"), {
            position: "top-right",
          })
        } else {
          this.$toast.error(this.$t("profile-upload-not-successful"), {
            position: "top-right",
          })
        }

        const application = {
          gender: this.gender,
          firstName: this.firstName,
          lastName: this.lastName,
          startDate: userAction.getDatesInString(this.startDate),
          endDate: userAction.getDatesInString(this.endDate),
          dateOfBirth: userAction.getDatesInString(
            this.dateOfBirth ?? new Date()
          ),
          street: this.street,
          zip: this.postCode,
          location: this.city,
          land: this.country,
          state: this.state,
          university: this.university,
          immatriculationUrl: this.ibSecretUrl,
          cvUrl: this.cvSecretUrl,
          profileUrl: this.photoUrl,
          motivationUrl: this.motivationSecretUrl,
          additional: null,
          motivationText: null,
          extraFileUrl: null,
          phoneNo: this.phoneNumber,
          hospitalId: this.hospitalId,
          doctorId: this.docId != "" ? this.docId : null,
        }

        const applicationResponse = await userAction.applyToDoctor(application)

        this.linkResponse = applicationResponse
        this.applicationSuccessful = applicationResponse.success

        if (applicationResponse.success) {
          this.$toast.success(this.$t("application-successful"), {
            position: "top-right",
          })
          this.$router.push({ path: "/" })
        } else {
          this.$toast.error(this.$t("application-not-successful"), {
            position: "top-right",
          })
        }

        this.isLoading = false
      }
      this.dialog = false
    },
  },
})
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}

@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
}
</style>
