import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { align: "center" }
const _hoisted_3 = { class: "flex flex-wrap mt-10" }
const _hoisted_4 = {
  class: "flex-item",
  justify: "start"
}
const _hoisted_5 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_6 = {
  class: "flex-item",
  justify: "start"
}
const _hoisted_7 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_8 = { class: "flex-item" }
const _hoisted_9 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_10 = { class: "flex-item" }
const _hoisted_11 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_12 = { class: "flex-item" }
const _hoisted_13 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_14 = {
  class: "flex-item",
  justify: "start"
}
const _hoisted_15 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_16 = {
  align: "center",
  class: "mt-5"
}
const _hoisted_17 = { align: "center" }
const _hoisted_18 = { style: {"display":"flex","justify-content":"center"} }
const _hoisted_19 = { align: "center" }
const _hoisted_20 = {
  key: 0,
  class: "mb-10"
}
const _hoisted_21 = { style: {"width":"70px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_status = _resolveComponent("request-status")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_photo_upload = _resolveComponent("photo-upload")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_request_status, {
      response: _ctx.requestResponse,
      isLoading: _ctx.loading
    }, null, 8, ["response", "isLoading"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("general-info")), 1),
        _createVNode(_component_v_divider, { class: "mt-4" })
      ])
    ]),
    _createElementVNode("div", null, [
      (_ctx.doctor && !_ctx.loading)
        ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_photo_upload, {
                photo: _ctx.photo,
                isHospital: true,
                onPhotoUploaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.photo = $event))
              }, null, 8, ["photo"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("doctor.title")), 1),
                  _createVNode(_component_v_select, {
                    class: "ml-2",
                    modelValue: _ctx.title,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
                    density: "compact",
                    placeholder: _ctx.$t('doctor.title'),
                    "prepend-inner-icon": "mdi-pencil",
                    items: _ctx.doctorTitle,
                    variant: "outlined",
                    style: {"min-width":"300px"}
                  }, null, 8, ["modelValue", "placeholder", "items"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("first-name")), 1),
                  _createVNode(_component_v_text_field, {
                    class: "ml-2",
                    modelValue: _ctx.firstName,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstName) = $event)),
                    density: "compact",
                    placeholder: _ctx.$t('first-name'),
                    "prepend-inner-icon": "mdi-pencil",
                    variant: "outlined",
                    rules: _ctx.notEmptyRule,
                    style: {"min-width":"300px"}
                  }, null, 8, ["modelValue", "placeholder", "rules"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("last-name")), 1),
                  _createVNode(_component_v_text_field, {
                    class: "ml-2",
                    modelValue: _ctx.lastName,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastName) = $event)),
                    density: "compact",
                    placeholder: _ctx.$t('last-name'),
                    "prepend-inner-icon": "mdi-pencil",
                    variant: "outlined",
                    rules: _ctx.notEmptyRule,
                    style: {"min-width":"300px"}
                  }, null, 8, ["modelValue", "placeholder", "rules"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("email")), 1),
                  _createVNode(_component_v_text_field, {
                    class: "ml-2",
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.email) = $event)),
                    density: "compact",
                    readonly: "",
                    placeholder: _ctx.$t('email'),
                    "prepend-inner-icon": "mdi-email-outline",
                    variant: "outlined",
                    style: {"min-width":"300px"}
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("phoneNumber")), 1),
                  _createVNode(_component_v_text_field, {
                    class: "ml-2",
                    modelValue: _ctx.phoneNumber,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.phoneNumber) = $event)),
                    density: "compact",
                    placeholder: _ctx.$t('phoneNumber'),
                    "prepend-inner-icon": "mdi-cellphone",
                    variant: "outlined",
                    style: {"min-width":"300px"}
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("hospital.department")), 1),
                  _createVNode(_component_v_autocomplete, {
                    class: "ml-2",
                    modelValue: _ctx.chosenDepartment,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.chosenDepartment) = $event)),
                    density: "compact",
                    placeholder: _ctx.$t('hospital.department'),
                    items: _ctx.departments,
                    "item-title": _ctx.getDepartmentName,
                    "item-value": _ctx.getDepartmentValue,
                    "prepend-inner-icon": "mdi-hospital-building",
                    variant: "outlined",
                    "menu-props": { width: '300px' }
                  }, null, 8, ["modelValue", "placeholder", "items", "item-title", "item-value"])
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("h3", _hoisted_16, _toDisplayString(_ctx.$t("doctor.choose-time")), 1),
    _createVNode(_component_v_divider, { class: "mt-4" }),
    _createElementVNode("div", _hoisted_17, [
      _createVNode(_component_v_card, {
        "max-width": "500",
        class: "mt-10 mb-10",
        style: {"border-radius":"2%"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_v_tooltip, { location: "top" }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_switch, _mergeProps(props, {
                  color: "primary",
                  modelValue: _ctx.activeSearch,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.activeSearch) = $event)),
                  label: _ctx.$t('doctor.calender')
                }), null, 16, ["modelValue", "label"])
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.$t("doctor.calender-tip")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_19, [
            (_ctx.activeSearch)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.manualTime, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "mt-5 mb-5",
                      key: index
                    }, [
                      _createVNode(_component_v_row, { style: {"justify-content":"space-evenly"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_VueDatePicker, {
                            modelValue: _ctx.manualTime[index][0],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.manualTime[index][0]) = $event),
                            range: "",
                            "enable-time-picker": false,
                            "min-date": Date.now(),
                            teleport: true,
                            style: {"width":"60%"},
                            clearable: false,
                            format: "dd MMM yyyy"
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "min-date"]),
                          _createElementVNode("div", _hoisted_21, [
                            _createVNode(_component_v_text_field, {
                              density: "compact",
                              modelValue: _ctx.manualTime[index][1],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.manualTime[index][1]) = $event),
                              "hide-details": "",
                              "single-line": "",
                              type: "number",
                              rules: _ctx.minRule
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                          ]),
                          _createVNode(_component_v_btn, {
                            class: "mt-2",
                            density: "compact",
                            color: "red",
                            icon: "mdi-trash-can-outline",
                            onClick: ($event: any) => (
                  _ctx.manualTime = _ctx.manualTime
                    .slice(0, index)
                    .concat(_ctx.manualTime.slice(index + 1))
                )
                          }, null, 8, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128)),
                  _createVNode(_component_v_btn, {
                    density: "compact",
                    color: "primary",
                    icon: "mdi-plus",
                    rules: _ctx.minRule,
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.manualTime.push([[], 1])))
                  }, null, 8, ["rules"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_v_footer, { style: {"display":"flex","width":"96%","justify-content":"end","background-color":"#f2f2f2"} }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_v_btn, {
                class: "mr-10",
                rounded: "lg",
                color: "#3fb984",
                variant: "flat",
                size: "large",
                width: "150",
                loading: _ctx.isSaving,
                disabled: _ctx.infoCompleted,
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.saveProfile()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
                ]),
                _: 1
              }, 8, ["loading", "disabled"])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ], 64))
}