<template>
  <div v-for="(doctor, index) in doctors" :key="index">
    <v-card
      class="mx-auto my-5 ml-3 mr-3"
      max-width="40%"
      height="130"
      v-bind="props"
    >
      <v-row align="center">
        <v-col cols="7" align="start">
          <div class="ml-2">
            <p style="font-size: 92%">{{ $t("name") }}:</p>
            <p style="font-weight: 500">{{ doctorName(doctor) }}</p>
            <p style="font-size: 92%" class="mt-2">
              {{ $t("hospital.department") }}:
            </p>
            <p style="font-weight: 500">{{ doctor.department ?? "-" }}</p>
          </div>
        </v-col>
        <v-col cols="5">
          <v-img
            class="mt-2"
            :src="doctor.photo"
            width="100"
            style="border-radius: 50%; cursor: pointer"
          ></v-img>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, defineProps, ref } from "vue"
import { Doctor, Hospital } from "@/types"

const { t } = useI18n()

const props = defineProps<{ hospital: Hospital | undefined }>()
const hospital = ref<Hospital | undefined>(props.hospital)

const doctors = computed((): Doctor[] => {
  var doctors: Doctor[] = []

  hospital.value?.doctors.forEach((d) => {
    doctors.push(d)
  })
  return doctors
})

const doctorName = (d: Doctor) => {
  return d.title + " " + d.firstName + " " + d.lastName
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
</style>
