<template>
  <request-status :response="requestResponse" :isLoading="isLoading" />
  <div class="mt-5">
    <div align="center">
      <h3>{{ $t("general-info") }}</h3>
      <v-divider class="mt-4"></v-divider>
    </div>
    <v-col v-if="doctor && !isLoading">
      <div class="mt-5">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-img
              v-bind="props"
              :width="163"
              style="border-radius: 50%"
              cover
              :src="
                photoUrl != ''
                  ? photoUrl
                  : require('../../../../assets/blank.png')
              "
              @click="onButtonClick"
            ></v-img>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onImageUpload"
            />
          </template>
          {{ $t("image-upload") }}
        </v-tooltip>
      </div>
      <div>
        <v-dialog v-model="showCropper">
          <v-sheet
            border="md"
            class="pa-6 text-black mx-auto"
            :width="smallWindow ? 320 : 600"
            color="white"
          >
            <h4 class="text-h5 font-weight-bold mb-4">
              <cropper
                class="cropper"
                :src="localImageUrl"
                :stencil-props="{
                  aspectRatio: 10 / 11,
                }"
                @change="change"
              />
              <div align="center" class="mt-3">
                <v-btn color="error" @click="showCropper = false">{{
                  $t("go-back")
                }}</v-btn>
                <v-btn class="ml-2" color="green" @click="saveImage">{{
                  $t("save")
                }}</v-btn>
              </div>
            </h4>
          </v-sheet>
        </v-dialog>
      </div>

      <div class="flex flex-wrap mt-10">
        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.title") }}
          </div>

          <v-select
            class="ml-2"
            v-model="title"
            density="compact"
            :placeholder="$t('doctor.title')"
            prepend-inner-icon="mdi-pencil"
            :items="doctorTitle"
            variant="outlined"
            style="min-width: 300px"
          ></v-select>
        </div>

        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("first-name") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="firstName"
            density="compact"
            :placeholder="$t('first-name')"
            prepend-inner-icon="mdi-pencil"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("last-name") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="lastName"
            density="compact"
            :placeholder="$t('last-name')"
            prepend-inner-icon="mdi-pencil"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("email") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="email"
            density="compact"
            readonly
            :placeholder="$t('email')"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("phoneNumber") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="phoneNumber"
            density="compact"
            :placeholder="$t('phoneNumber')"
            prepend-inner-icon="mdi-cellphone"
            variant="outlined"
            style="min-width: 300px"
          ></v-text-field>
        </div>
        <div class="flex-item"></div>
      </div>

      <div class="mt-5 mb-2 ml-2">
        <h3 align="start">{{ $t("doctor.clinic-info") }}</h3>
      </div>
      <div class="flex flex-wrap">
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("street") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="street"
            density="compact"
            :placeholder="$t('street')"
            prepend-inner-icon="mdi-map-marker"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("post") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="postCode"
            density="compact"
            :placeholder="$t('post')"
            prepend-inner-icon="mdi-numeric"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("city") }}
          </div>
          <v-autocomplete
            class="ml-2"
            v-model="chosenAddress"
            density="compact"
            :placeholder="$t('city')"
            :items="addressOptions"
            :item-title="getCityAddress"
            :item-value="getCityAddressValue"
            prepend-inner-icon="mdi-city"
            variant="outlined"
            :rules="notEmptyRule"
            @input="emitCityInput"
            style="min-width: 300px"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" v-if="!cityLoading">
                <template v-slot:title> {{ item.raw.name }} </template>
                <template v-slot:subtitle> {{ item.raw.country }} </template>
              </v-list-item>
            </template>
            <template v-slot:no-data>
              <p class="font-weight-regular ml-4 mt-2 mb-2">
                {{ $t("no-data") }}
              </p></template
            >
            <template v-slot:append-inner>
              <v-progress-circular
                v-if="cityLoading"
                indeterminate
                class="ml-2"
              ></v-progress-circular> </template
          ></v-autocomplete>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("state") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="state"
            density="compact"
            :placeholder="$t('state')"
            prepend-inner-icon="mdi-map-marker"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("country") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="country"
            density="compact"
            :placeholder="$t('country')"
            prepend-inner-icon="mdi-earth"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.teaching-uni") }}
          </div>

          <v-autocomplete
            class="ml-2"
            v-model="university"
            :items="universities"
            :item-title="getUniversityName"
            :items-icons="getUniversityLogo"
            density="compact"
            :placeholder="$t('university')"
            variant="outlined"
            style="min-width: 300px"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props">
                <template v-slot:prepend>
                  <v-img :src="item.raw.logo" width="20" class="mr-5"></v-img>
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.specialty") }}
          </div>
          <v-autocomplete
            class="ml-2"
            v-model="specialty"
            density="compact"
            :placeholder="$t('doctor.specialty')"
            prepend-inner-icon="mdi-school"
            variant="outlined"
            :items="specialties"
            :menu-props="{ width: '300px' }"
            style="min-width: 300px"
          ></v-autocomplete>
        </div>
        <div class="flex-item mb-10">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.link") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="link"
            density="compact"
            :placeholder="$t('doctor.link')"
            prepend-inner-icon="mdi-link"
            variant="outlined"
            style="min-width: 300px"
          ></v-text-field>
        </div>
      </div>
    </v-col>
    <v-footer
      style="
        display: flex;
        width: 96%;
        justify-content: end;
        background-color: #f2f2f2;
      "
    >
      <div>
        <div>
          <v-btn
            class="mr-10"
            rounded="lg"
            color="#3fb984"
            variant="flat"
            size="large"
            width="150"
            :loading="isSaving"
            :disabled="!infoCompleted"
            @click="saveProfileData()"
          >
            {{ $t("save") }}
          </v-btn>
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script lang="ts">
import { AutoCompleteAddress, DoctorProfile, Universities } from "@/types"
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue"
import { useI18n } from "vue-i18n"
import { useUserActions } from "@/stores/user-actions"
import { useToast } from "vue-toast-notification"
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"
import { doctorActions } from "@/stores/doctor-actions"
import { specialties } from "@/constants/speciality"
import { SPECIALIZATION_DATA_DE, doctorTitle } from "@/types/spec"
import { commonStore } from "@/stores/common-store"
import { doctorAuthStore } from "@/stores/doctor-auth"
export default defineComponent({
  name: "DoctorGeneralInformation",
  components: {
    Cropper,
  },

  setup(props, { emit }) {
    const { t } = useI18n()
    const docAction = doctorActions()
    const $toast = useToast()
    const common = commonStore()
    const firstName = ref<string | undefined>(undefined)
    const lastName = ref<string | undefined>(undefined)
    const email = ref<string | undefined>(undefined)
    const title = ref<string | undefined>(undefined)
    const dateOfBirth = ref<Date | null>(null)
    const today = ref(new Date())
    const street = ref<string | undefined>(undefined)
    const postCode = ref<string | undefined>(undefined)
    const city = ref<string | undefined>(undefined)
    const state = ref<string | undefined>(undefined)
    const country = ref<string | undefined>(undefined)
    const phoneNumber = ref<string | undefined>(undefined)
    const university = ref<string | undefined>(undefined)
    const specialty = ref<string | undefined>(undefined)
    const specialties = ref(SPECIALIZATION_DATA_DE)
    const photoUrl = ref<string>("")
    const internshipSpecialty = ref<string | undefined>(undefined)
    const area = ref<string | undefined>(undefined)
    const financialHelp = ref<string | undefined>(undefined)
    const requirements = ref<string | undefined>(undefined)
    const requestResponse = ref({})
    const isLoading = ref(false)
    const link = ref<string | undefined>(undefined)
    const isSelecting = ref(false)
    const isSaving = ref(false)
    const doctor = ref<DoctorProfile>()
    const localImageUrl = ref("")
    const showCropper = ref(false)
    const canvas = ref()
    const smallWindow = ref(window.innerWidth < 1000)
    const addressOptions = ref<AutoCompleteAddress[]>([])
    const longitude = ref<number>(0)
    const latitude = ref<number>(0)
    const chosenAddress = ref<AutoCompleteAddress>()
    const cityLoading = ref(false)
    let timeoutId: ReturnType<typeof setTimeout> | null = null
    const universities = ref<Universities[]>([])
    onMounted(async () => {
      await getProfile()

      title.value = doctor.value?.title ?? "-"
      firstName.value = doctor.value?.firstName ?? undefined
      lastName.value = doctor.value?.lastName ?? undefined
      email.value = doctor.value?.email ?? undefined
      street.value = doctor.value?.clinicStreet ?? undefined
      postCode.value = doctor.value?.clinicZip ?? undefined
      city.value = doctor.value?.clinicLocation ?? undefined
      state.value = doctor.value?.clinicState ?? undefined
      country.value = doctor.value?.clinicLand ?? undefined
      phoneNumber.value = doctor.value?.privatePhone ?? undefined
      university.value = doctor.value?.university ?? undefined
      photoUrl.value = doctor.value?.photo ?? ""
      link.value = doctor.value?.linkedInLink
      internshipSpecialty.value = doctor.value?.selectReason
      area.value = doctor.value?.areaDescription
      financialHelp.value = doctor.value?.financialDescription
      requirements.value = doctor.value?.requirementsForStudent
      specialty.value = doctor.value?.specialty
      chosenAddress.value = {
        name: doctor.value?.clinicLocation ?? "",
        country: doctor.value?.clinicLand ?? "",
        postcode: doctor.value?.clinicZip ?? "",
        state: doctor.value?.clinicState ?? "",
        coordinates: [
          doctor.value?.location?.coordinates[0] ?? 0,
          doctor.value?.location?.coordinates[1] ?? 0,
        ],
      }
      await getUniversities()
    })

    const getUniversities = async () => {
      isLoading.value = true
      const response = await docAction.getUniversities()
      requestResponse.value = response
      if (response.success) {
        universities.value = response.result as Universities[]
      }
      isLoading.value = false
    }

    const getProfile = async () => {
      isLoading.value = true
      const response = await docAction.getProfile()
      requestResponse.value = response
      if (response.success) {
        doctor.value = response.result as DoctorProfile
      }
      isLoading.value = false
    }

    const notEmptyRule = [(v: string) => !!v || t("field-required")]

    const getUniversityName = (uni: Universities): string => {
      return uni.name
    }
    const getUniversityLogo = (uni: Universities): string => {
      return uni.logo
    }

    const formattedDateOfBirth = computed(() => {
      return dateOfBirth.value ? dateOfBirth.value.toLocaleDateString() : ""
    })

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    const emitCityInput = async (input: any) => {
      cityLoading.value = true
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }

      timeoutId = setTimeout(async () => {
        searchLocations(input.target.value)
      }, 500)
    }

    const searchLocations = async (val: string) => {
      addressOptions.value = []
      const response = await common.searchLocation(val)
      response.features.forEach((feature, index) => {
        const address = {
          name: feature.properties.name,
          state: feature.properties.state ?? "",
          postcode: feature.properties.postcode ?? "",
          country: feature.properties.country,
          coordinates: feature.geometry.coordinates,
        }
        cityLoading.value = false

        addressOptions.value.push(address)
      })
    }

    const getCityAddress = (address: AutoCompleteAddress): string | null => {
      if (address) {
        return `${address["name"]}`
      }
      return null
    }
    const getCityAddressValue = (address: AutoCompleteAddress): any => {
      return address
    }

    watch([chosenAddress], () => {
      city.value = chosenAddress.value?.["name"]
      country.value = chosenAddress.value?.["country"]
      postCode.value = chosenAddress.value?.["postcode"]
      state.value = chosenAddress.value?.["state"]
      latitude.value = chosenAddress.value?.["coordinates"][1] ?? 0
      longitude.value = chosenAddress.value?.["coordinates"][0] ?? 0
    })

    const infoCompleted = computed(() => {
      return (
        firstName.value != "" &&
        lastName.value != "" &&
        street.value != "" &&
        postCode.value != "" &&
        city.value != "" &&
        state.value != "" &&
        country.value != "" &&
        specialty.value != undefined
      )
    })

    return {
      firstName,
      lastName,
      email,
      doctorTitle,
      title,
      dateOfBirth,
      formattedDateOfBirth,
      today,
      street,
      postCode,
      city,
      state,
      country,
      phoneNumber,
      university,
      requestResponse,
      isLoading,
      specialty,
      specialties,
      link,
      doctor,
      isSelecting,
      photoUrl,
      infoCompleted,
      isSaving,
      smallWindow,
      $toast,
      localImageUrl,
      showCropper,
      canvas,
      internshipSpecialty,
      area,
      financialHelp,
      requirements,
      notEmptyRule,
      getUniversityName,
      getUniversityLogo,
      getCityAddress,
      getCityAddressValue,
      chosenAddress,
      addressOptions,
      emitCityInput,
      longitude,
      latitude,
      cityLoading,
      universities,
    }
  },
  methods: {
    onButtonClick() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      if (this.$refs.uploader instanceof HTMLInputElement) {
        this.$refs.uploader.click()
      }
    },
    change({ coordinates, canvas }: any) {
      this.canvas = canvas
    },
    saveImage() {
      const docAction = doctorActions()

      this.canvas.toBlob(async (blob: Blob) => {
        const formData = new FormData()
        formData.append("file", blob)

        const response = await docAction.uploadImage(formData)
        this.requestResponse = response
        if (response.success) {
          this.photoUrl = (response.result as string[])[0]
        }
        this.showCropper = false
      })
    },

    onImageUpload(e: any) {
      const image = e.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(image)

      reader.onload = async (e) => {
        this.localImageUrl = e?.target?.result as string
        this.showCropper = true
      }
    },

    async saveProfileData(): Promise<void> {
      this.isSaving = true
      const docAction = doctorActions()

      const data = {
        title: this.title,
        firstName: this.firstName,
        lastName: this.lastName,
        privatePhone: this.phoneNumber,
        clinicStreet: this.street,
        clinicZip: this.postCode,
        clinicLocation: this.city,
        clinicLand: this.country,
        clinicState: this.state,
        university: this.university,
        photo: this.photoUrl,
        linkedInLink: this.link,
        specialty: this.specialty,
        location: {
          type: "Point",
          coordinates: [this.longitude, this.latitude],
        },
      }

      const response = await docAction.updateProfile(data)
      this.requestResponse = response

      if (response.success) {
        if (this.photoUrl) {
          docAction.doctorProfileLink = this.photoUrl
        }
        this.$toast.success(this.$t("profile-upload-successful"), {
          position: "bottom-left",
        })
      } else {
        this.$toast.error(this.$t("profile-not-upload-successful"), {
          position: "bottom-left",
        })
      }

      this.isSaving = false
    },
  },
})
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}

@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
}
</style>
