<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card
        class="mx-auto my-5 ml-3 mr-3"
        :width="!smallWindow ? 400 : 300"
        v-bind="props"
        height="400"
        :elevation="isHovering ? 20 : 3"
      >
        <v-card-item
          :title="
            prop.doctor.title +
            ' ' +
            prop.doctor.firstName +
            ' ' +
            prop.doctor.lastName
          "
          @click="$router.push(`/doctors/${prop.doctor._id}`)"
          style="cursor: pointer"
        >
          <template v-slot:subtitle>
            <div align="center">
              <div class="mt-2" style="white-space: normal">
                {{ prop.doctor.specialty }}
              </div>
            </div>
          </template>
        </v-card-item>

        <v-row align="center" justify="center">
          <v-col cols="6">
            <v-badge color="white">
              <v-img
                width="150"
                style="border-radius: 50%; cursor: pointer"
                :src="
                  prop.doctor.photo ??
                  require('../../../assets/profile_def.png')
                "
                @click="$router.push(`/doctors/${prop.doctor._id}`)"
              >
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </div> </template
              ></v-img>
              <template
                v-slot:badge
                v-if="doctor.universityLogo && !doctor.isHospitalConnected"
              >
                <div class="mt-5">
                  <v-img
                    width="50"
                    style="border-radius: 50%"
                    :src="prop.doctor.universityLogo"
                  ></v-img>
                </div>
              </template>
              <template v-slot:badge v-else>
                <div class="mt-5">
                  <v-img
                    width="50"
                    style="border-radius: 50%; cursor: pointer"
                    :src="require('../../../assets/hospital.png')"
                    @click="$router.push(`hospital/${doctor.hospitalId[0]}`)"
                  ></v-img>
                </div>
              </template>
            </v-badge>
          </v-col>
        </v-row>

        <div class="d-flex py-3 justify-space-between" style="height: 80px">
          <v-btn
            class="ml-5"
            icon="mdi-map-marker"
            size="small"
            @click="openDocLocation"
          ></v-btn>

          <v-list-item-subtitle class="mt-5">
            {{ prop.doctor.clinicLocation + ", " + prop.doctor.clinicLand }}
          </v-list-item-subtitle>

          <div>
            <v-btn
              :disabled="!doctor.linkedInLink"
              class="mr-5"
              icon="mdi-web"
              size="small"
              @click="toDoctorLink"
            ></v-btn>
          </div>
        </div>

        <v-card-actions
          style="
            background-color: #3fb984;
            position: absolute;
            bottom: 0;
            width: 100%;
          "
        >
          <v-btn
            block
            color="white"
            @click="$router.push(`/doctors/${prop.doctor._id}`)"
          >
            {{ $t("doctor.to-profile") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { defineProps, ref } from "vue"
import { Doctor } from "@/types"

const prop = defineProps<{
  doctor: Doctor
}>()

const { t } = useI18n()
const smallWindow = ref(window.innerWidth < 1000)

const openDocLocation = (): void => {
  const address = prop.doctor.clinicStreet.split(" ")

  window.open(
    `https://www.google.com/maps/search/${address[0] ?? ""}+${
      address[1] ?? ""
    }+${prop.doctor.clinicLocation ?? ""}+${prop.doctor.clinicLand}`,
    "_blank"
  )
}

const toDoctorLink = (): void => {
  const headerLink = prop.doctor.linkedInLink.split(":", 1)
  const wwwLink = prop.doctor.linkedInLink.split(".", 1)
  console.log(headerLink)
  if (headerLink[0] == "https") {
    window.open(prop.doctor.linkedInLink, "_blank")
  } else if (wwwLink[0] == "www") {
    window.open("https://" + prop.doctor.linkedInLink, "_blank")
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set height to full viewport height */
}
</style>
