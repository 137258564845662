<template>
  <v-btn
    class="ml-5 mr-5 mt-2 mb-5"
    icon="mdi-filter-variant"
    color="#3fb984"
    size="small"
    @click="showFilterDialog = true"
  ></v-btn>

  <!-- @click="$emit('filter')" -->
  <v-dialog max-width="500" v-model="showFilterDialog">
    <v-card rounded="lg">
      <v-card-title class="d-flex justify-space-between mb-5">
        <div></div>
        <div class="mt-2">Filter</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click="showFilterDialog = false"
        ></v-btn>
      </v-card-title>

      <div class="d-flex justify-space-evenly">
        <v-tabs
          v-model="filterTabs"
          show-arrows
          bg-color="#25344d"
          fixed-tabs
          style="width: 100%"
        >
          <v-tab value="privateClinic">CLINICS</v-tab>

          <v-tab value="hospitalAttached">HOSPITALS</v-tab>
        </v-tabs>
      </div>
      <v-window v-model="filterTabs">
        <v-window-item value="privateClinic">
          <div class="mt-2" align="center">
            <v-autocomplete
              class="ml-4 mr-4"
              prepend-inner-icon="mdi-doctor"
              v-model="chosenSpecialty"
              :items="
                locale == 'de' ? SPECIALIZATION_DATA_DE : SPECIALIZATION_DATA_EN
              "
              :label="$t('search.specialty')"
              outlined
              :menu-props="{ width: '700px' }"
              style="min-width: 250px"
            ></v-autocomplete>
          </div>
        </v-window-item>
        <v-window-item value="hospitalAttached">
          <div class="mt-2" align="center">
            <v-autocomplete
              v-model="chosenHospital"
              label="Hospitals"
              :items="hospitals"
              :item-title="getHospitalName"
              :item-value="getHospital"
              style="width: 90%"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props">
                  <template v-slot:subtitle
                    >{{ item.raw.hospitalLocation }},
                    {{ item.raw.hospitalLand }}
                  </template>
                </v-list-item>
              </template></v-autocomplete
            >

            <v-autocomplete
              v-model="chosenDepartment"
              label="Abteilung"
              :items="hospitals"
              :item-title="getHospitalName"
              :item-value="getHospital"
              style="width: 90%"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props">
                  <template v-slot:subtitle
                    >{{ item.raw.hospitalLocation }},
                    {{ item.raw.hospitalLand }}
                  </template>
                </v-list-item>
              </template></v-autocomplete
            >
          </div>
        </v-window-item>
      </v-window>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="flat" class="mr-2" @click="resetFilter">Reset</v-btn>
        <v-btn color="#3fb984" variant="flat" @click="applyFilter">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref } from "vue"
import { onMounted } from "vue"
import { commonStore } from "@/stores/common-store"
import { hospitalActions } from "@/stores/hospital-actions"
import { SPECIALIZATION_DATA_DE, SPECIALIZATION_DATA_EN } from "@/types/spec"

interface SearchFilterDoc {
  includeHospital: boolean
  includeClinic: boolean
  hospitalValue: string | null
  departmentValue: string | null
  clinic: string | null
  reset: boolean
}

const emit = defineEmits(["filter-applied", "close"])
const props = defineProps({
  type: {
    type: String,
  },
  openMenu: {
    type: Boolean,
  },
  specialty: {
    type: String,
  },
})

const includeHospital = ref(false)
const includeClinic = ref(false)
const publicStore = commonStore()
const hospitals = ref()
const showFilterDialog = ref(false)
const chosenHospital = ref<string | null>(null)
const chosenDepartment = ref<string | null>(null)
const chosenSpecialty = ref<string | null>(null)
const locale = ref(localStorage.getItem("locale") ?? "de")
const filterTabs = ref<string | null>(null)
const hospitalAction = hospitalActions()
const openMenu = ref(false)

onMounted(async () => {
  if (props.type) {
    filterTabs.value = props.type
  }
  loadSpecialty()
  await getHospitals()
})

const loadSpecialty = () => {
  if (props.specialty) {
    chosenSpecialty.value = props.specialty
    //   if (locale.value == "de") {

    //   } else {
    //     const reverseSpecialtiesMap: SpecialtiesMap = {}
    //     for (const [key, value] of Object.entries(specialtiesMap)) {
    //       reverseSpecialtiesMap[value] = key
    //     }
    //     chosenSpecialty.value = reverseSpecialtiesMap[props.specialty]
    //   }
  }
}

const applyFilter = async () => {
  if (filterTabs.value == "privateClinic") {
    includeClinic.value = true
  } else if (filterTabs.value == "hospitalAttached") {
    includeHospital.value = true
  }
  const searchFilter: SearchFilterDoc = {
    includeHospital: includeHospital.value,
    includeClinic: includeClinic.value,
    hospitalValue: chosenHospital.value,
    departmentValue: chosenDepartment.value,
    clinic: chosenSpecialty.value,
    reset: false,
  }
  emit("filter-applied", searchFilter)
  includeHospital.value = false
  includeClinic.value = false

  showFilterDialog.value = false
}

const resetFilter = async () => {
  const searchFilter: SearchFilterDoc = {
    includeHospital: false,
    includeClinic: false,
    hospitalValue: null,
    departmentValue: null,
    clinic: null,
    reset: true,
  }

  emit("filter-applied", searchFilter)
  showFilterDialog.value = false
}
onMounted(async () => {
  window.addEventListener("locale-changed", updateLocale)
  window.addEventListener("locale-changed", loadSpecialty)
})

const updateLocale = () => {
  locale.value = localStorage.getItem("locale") ?? "de"
}

// const getDepartmentName = computed(() => (department: Department): string => {
//   return getLocale.value == "de" ? department.de : department.en
// })
// const getDepartmentValue = (department: Department): string => {
//   return department.value
// }

const getHospitalName = (hospital: any): string => {
  return hospital.name
}

const getHospitals = async (): Promise<void> => {
  const response = await publicStore.getAllHospitals()
  if (response.success) {
    hospitals.value = response.result
  }
}
const getHospital = (hospital: any): any => {
  return hospital
}
</script>

<style scoped></style>
