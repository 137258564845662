import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60df9c89"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  align: "center"
}
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  class: "mt-4",
  align: "center"
}
const _hoisted_5 = { align: "center" }
const _hoisted_6 = { class: "flex-item" }
const _hoisted_7 = { class: "mt-5" }
const _hoisted_8 = { class: "mt-5" }
const _hoisted_9 = { class: "flex-item" }
const _hoisted_10 = { class: "mt-5" }
const _hoisted_11 = { class: "flex-item" }
const _hoisted_12 = { class: "mt-5" }
const _hoisted_13 = {
  class: "flex-item mt-5",
  align: "center"
}
const _hoisted_14 = { class: "font-weight-medium" }
const _hoisted_15 = {
  align: "center",
  class: "mt-5"
}
const _hoisted_16 = { class: "flex flex-wrap mt-10" }
const _hoisted_17 = {
  class: "flex-item",
  justify: "start"
}
const _hoisted_18 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_19 = {
  class: "flex-item",
  justify: "start"
}
const _hoisted_20 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_21 = { class: "flex-item" }
const _hoisted_22 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_23 = { class: "flex-item" }
const _hoisted_24 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_25 = { class: "flex-item" }
const _hoisted_26 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_27 = { class: "flex-item" }
const _hoisted_28 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_29 = { class: "flex-item" }
const _hoisted_30 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_31 = { class: "flex-item" }
const _hoisted_32 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_33 = { class: "flex-item" }
const _hoisted_34 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_35 = { class: "flex-item" }
const _hoisted_36 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_37 = { class: "flex-item" }
const _hoisted_38 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_39 = { class: "flex-item" }
const _hoisted_40 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_41 = { class: "flex-item" }
const _hoisted_42 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_43 = { class: "flex-item" }
const _hoisted_44 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_45 = { class: "flex-item" }
const _hoisted_46 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_47 = {
  key: 0,
  class: "flex-item"
}
const _hoisted_48 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_49 = {
  key: 1,
  class: "flex-item"
}
const _hoisted_50 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_51 = {
  key: 2,
  class: "flex-item"
}
const _hoisted_52 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_53 = {
  key: 3,
  class: "flex-item"
}
const _hoisted_54 = {
  key: 4,
  class: "flex-item mt-7"
}
const _hoisted_55 = {
  key: 0,
  align: "center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_status = _resolveComponent("request-status")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_request_status, {
      response: _ctx.requestResponse,
      isLoading: _ctx.isLoading
    }, null, 8, ["response", "isLoading"]),
    (_ctx.isStudent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("your-application")) + " " + _toDisplayString(_ctx.docName), 1),
          _createVNode(_component_v_divider, { class: "mt-3" })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("application-from")) + " " + _toDisplayString(_ctx.application?.firstName) + " " + _toDisplayString(_ctx.application?.lastName), 1),
          _createVNode(_component_v_divider, { class: "mt-3" })
        ])),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: _ctx.smallWindow ? 12 : 3
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_v_img, {
                    class: "ml-7",
                    width: 100,
                    style: {"border-radius":"50%"},
                    cover: "",
                    src: 
                _ctx.application?.profileUrl !== ''
                  ? _ctx.application?.profileUrl
                  : require('../../../assets/blank.png')
              
                  }, null, 8, ["src"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_v_btn, {
                    loading: _ctx.isLoading,
                    color: _ctx.cvUrl ? 'primary' : 'red',
                    width: "130",
                    class: "ml-5 mb-2",
                    href: _ctx.cvUrl ?? '',
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("resume")), 1)
                    ]),
                    _: 1
                  }, 8, ["loading", "color", "href"])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({ loading: _ctx.isLoading }, props, {
                        color: _ctx.ibUrl ? 'primary' : 'red',
                        class: "ml-5 mb-2",
                        href: _ctx.ibUrl ?? '',
                        target: "_blank",
                        width: "130"
                      }), {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("ib-short")), 1)
                        ]),
                        _: 2
                      }, 1040, ["loading", "color", "href"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("ib")), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({ loading: _ctx.isLoading }, props, {
                        color: _ctx.motivationUrl ? 'primary' : 'red',
                        width: "130",
                        class: "ml-5 mb-2",
                        href: _ctx.motivationUrl,
                        target: "_blank"
                      }), {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("motivation")), 1)
                        ]),
                        _: 2
                      }, 1040, ["loading", "color", "href"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("tip-motivation")), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("application-status")) + ":", 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.statusMapping?.[_ctx.application?.status]), 1),
                _createElementVNode("div", _hoisted_15, [
                  (_ctx.application?.status == 'ACCEPTED')
                    ? (_openBlock(), _createBlock(_component_v_img, {
                        key: 0,
                        width: "100px",
                        src: require('../../../assets/happy.png')
                      }, null, 8, ["src"]))
                    : _createCommentVNode("", true),
                  (_ctx.application?.status == 'REJECTED')
                    ? (_openBlock(), _createBlock(_component_v_img, {
                        key: 1,
                        width: "100px",
                        src: require('../../../assets/sad.png')
                      }, null, 8, ["src"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["cols"]),
        _createVNode(_component_v_col, {
          cols: _ctx.smallWindow ? 12 : 9
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("gender")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  density: "compact",
                  "model-value": _ctx.application?.gender,
                  readonly: "",
                  placeholder: _ctx.$t('gender'),
                  "prepend-inner-icon": "mdi-gender-male-female",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("first-name")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  density: "compact",
                  "model-value": _ctx.application?.firstName,
                  readonly: "",
                  placeholder: _ctx.$t('first-name'),
                  "prepend-inner-icon": "mdi-pencil",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("last-name")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  density: "compact",
                  "model-value": _ctx.application?.lastName,
                  readonly: "",
                  placeholder: _ctx.$t('last-name'),
                  "prepend-inner-icon": "mdi-pencil",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("email")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  "model-value": _ctx.application?.userEmail,
                  readonly: "",
                  density: "compact",
                  placeholder: _ctx.$t('email'),
                  "prepend-inner-icon": "mdi-email-outline",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("birthday")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  density: "compact",
                  variant: "outlined",
                  readonly: "",
                  "model-value": _ctx.dateOfBirth,
                  "prepend-inner-icon": "mdi-calendar",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value"])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t("phoneNumber")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  "model-value": _ctx.application?.phoneNo,
                  readonly: "",
                  density: "compact",
                  placeholder: _ctx.$t('phoneNumber'),
                  "prepend-inner-icon": "mdi-cellphone",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("street")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  "model-value": _ctx.application?.street,
                  readonly: "",
                  density: "compact",
                  placeholder: _ctx.$t('street'),
                  "prepend-inner-icon": "mdi-map-marker",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t("post")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  "model-value": _ctx.application?.zip,
                  readonly: "",
                  density: "compact",
                  placeholder: _ctx.$t('post'),
                  "prepend-inner-icon": "mdi-numeric",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t("city")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  "model-value": _ctx.application?.location,
                  readonly: "",
                  density: "compact",
                  placeholder: _ctx.$t('city'),
                  "prepend-inner-icon": "mdi-city",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t("state")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  "model-value": _ctx.application?.state,
                  readonly: "",
                  density: "compact",
                  placeholder: _ctx.$t('state'),
                  "prepend-inner-icon": "mdi-map-marker",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.$t("country")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  "model-value": _ctx.application?.land,
                  readonly: "",
                  density: "compact",
                  placeholder: _ctx.$t('country'),
                  "prepend-inner-icon": "mdi-earth",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t("university")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  "model-value": _ctx.application?.university,
                  readonly: "",
                  density: "compact",
                  placeholder: _ctx.$t('university'),
                  "prepend-inner-icon": "mdi-school",
                  variant: "outlined",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t("applied-on")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  density: "compact",
                  variant: "outlined",
                  readonly: "",
                  "model-value": _ctx.appliedOn,
                  "prepend-inner-icon": "mdi-calendar",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value"])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.$t("table.start-date")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  density: "compact",
                  variant: "outlined",
                  readonly: "",
                  "model-value": _ctx.startDate,
                  "prepend-inner-icon": "mdi-calendar",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value"])
              ]),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.$t("table.end-date")), 1),
                _createVNode(_component_v_text_field, {
                  class: "ml-2",
                  density: "compact",
                  variant: "outlined",
                  readonly: "",
                  "model-value": _ctx.endDate,
                  "prepend-inner-icon": "mdi-calendar",
                  style: {"min-width":"200px"}
                }, null, 8, ["model-value"])
              ]),
              (_ctx.isHospital && _ctx.singleApplication?.status == 'CREATED')
                ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                    _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t("action.select-doctor")), 1),
                    _createVNode(_component_v_select, {
                      class: "ml-2",
                      modelValue: _ctx.selectedDoctor,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDoctor) = $event)),
                        _ctx.updateAssignedDoctor
                      ],
                      density: "compact",
                      placeholder: _ctx.$t('doctor.doctor'),
                      items: _ctx.hospitalDoctors,
                      "item-title": _ctx.doctorName,
                      "item-value": _ctx.doctorValue,
                      variant: "outlined"
                    }, {
                      item: _withCtx(({ props, item }) => [
                        _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                          subtitle: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.raw.department), 1)
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder", "items", "item-title", "item-value", "onUpdate:modelValue"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isHospital && _ctx.singleApplication?.status !== 'CREATED')
                ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                    _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$t("action.selected-doctor")), 1),
                    _createVNode(_component_v_text_field, {
                      class: "ml-2",
                      readonly: "",
                      "model-value": _ctx.docName,
                      density: "compact",
                      variant: "outlined"
                    }, null, 8, ["model-value"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.singleApplication?.comment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                    _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.$t("action.comment")), 1),
                    _createVNode(_component_v_textarea, {
                      class: "ml-2",
                      "model-value": _ctx.singleApplication?.comment,
                      readonly: "",
                      "row-height": "25",
                      rows: "3",
                      density: "compact",
                      placeholder: _ctx.$t('action.comment'),
                      "prepend-inner-icon": "mdi-comment",
                      variant: "outlined",
                      style: {"min-width":"200px"}
                    }, null, 8, ["model-value", "placeholder"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isStudent && _ctx.application?.status == 'CREATED')
                ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                    _createVNode(_component_v_dialog, {
                      modelValue: _ctx.takeBackDialog,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.takeBackDialog) = $event)),
                      "max-width": "500",
                      persistent: ""
                    }, {
                      activator: _withCtx(({ props: activatorProps }) => [
                        _createVNode(_component_v_btn, _mergeProps(activatorProps, {
                          color: "#3fb984",
                          class: "mr-4 mb-5"
                        }), {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("take-back")), 1)
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, {
                          "prepend-icon": "mdi-cancel",
                          text: _ctx.$t('action.take-back-text'),
                          title: _ctx.$t('action.take-back-title')
                        }, {
                          actions: _withCtx(() => [
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.takeBack()))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("go-back")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_btn, {
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.takeBack(_ctx.application?._id)))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("take-back")), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["text", "title"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_54, [
                    (
              !_ctx.singleApplication?.comment &&
              !_ctx.isStudent &&
              _ctx.application?.status == 'CREATED'
            )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                          _createVNode(_component_v_dialog, {
                            modelValue: _ctx.acceptDialog,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.acceptDialog) = $event)),
                            "max-width": "500"
                          }, {
                            activator: _withCtx(({ props: activatorProps }) => [
                              _createVNode(_component_v_btn, _mergeProps(activatorProps, {
                                color: "#3fb984",
                                class: "mr-4 mb-5",
                                disabled: !_ctx.doctorChosen
                              }), {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("action.accept")), 1)
                                ]),
                                _: 2
                              }, 1040, ["disabled"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, {
                                "prepend-icon": "mdi-check",
                                text: _ctx.$t('action.accept-application-text'),
                                title: _ctx.$t('action.accept-application')
                              }, {
                                actions: _withCtx(() => [
                                  _createVNode(_component_v_spacer),
                                  _createVNode(_component_v_btn, {
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateStatus('', '')))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("go-back")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_btn, {
                                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateStatus(_ctx.application?._id, 'ACCEPTED')))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("action.accept")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_v_textarea, {
                                    modelValue: _ctx.comments,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.comments) = $event)),
                                    class: "ml-5 mr-5",
                                    label: _ctx.$t('action.comment'),
                                    "row-height": "25",
                                    rows: "3",
                                    density: "compact",
                                    variant: "outlined",
                                    "prepend-inner-icon": "mdi-comment"
                                  }, null, 8, ["modelValue", "label"])
                                ]),
                                _: 1
                              }, 8, ["text", "title"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_v_dialog, {
                            modelValue: _ctx.rejectDialog,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.rejectDialog) = $event)),
                            "max-width": "500"
                          }, {
                            activator: _withCtx(({ props: activatorProps }) => [
                              _createVNode(_component_v_btn, _mergeProps(activatorProps, {
                                color: "red",
                                class: "ml-4 mb-5"
                              }), {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("action.reject")), 1)
                                ]),
                                _: 2
                              }, 1040)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, {
                                "prepend-icon": "mdi-undo",
                                text: _ctx.$t('action.reject-application-text'),
                                title: _ctx.$t('action.reject-application')
                              }, {
                                actions: _withCtx(() => [
                                  _createVNode(_component_v_spacer),
                                  _createVNode(_component_v_btn, {
                                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateStatus('', '')))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("go-back")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_btn, {
                                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateStatus(_ctx.application?._id, 'REJECTED')))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t("action.reject")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_v_textarea, {
                                    modelValue: _ctx.comments,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.comments) = $event)),
                                    class: "ml-5 mr-5",
                                    label: _ctx.$t('action.comment'),
                                    "row-height": "25",
                                    rows: "3",
                                    density: "compact",
                                    variant: "outlined",
                                    "prepend-inner-icon": "mdi-comment"
                                  }, null, 8, ["modelValue", "label"])
                                ]),
                                _: 1
                              }, 8, ["text", "title"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
            ])
          ]),
          _: 1
        }, 8, ["cols"])
      ]),
      _: 1
    })
  ], 64))
}