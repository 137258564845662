<template>
  <request-status :response="requestResponse" :isLoading="loading" />
  <div class="mt-5">
    <div align="center">
      <h3>{{ $t("general-info") }}</h3>
      <v-divider class="mt-4"></v-divider>
    </div>
  </div>
  <div>
    <v-col v-if="doctor && !loading">
      <photo-upload
        :photo="photo"
        :isHospital="true"
        @photo-uploaded="photo = $event"
      />
      <div class="flex flex-wrap mt-10">
        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("doctor.title") }}
          </div>

          <v-select
            class="ml-2"
            v-model="title"
            density="compact"
            :placeholder="$t('doctor.title')"
            prepend-inner-icon="mdi-pencil"
            :items="doctorTitle"
            variant="outlined"
            style="min-width: 300px"
          ></v-select>
        </div>

        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("first-name") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="firstName"
            density="compact"
            :placeholder="$t('first-name')"
            prepend-inner-icon="mdi-pencil"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("last-name") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="lastName"
            density="compact"
            :placeholder="$t('last-name')"
            prepend-inner-icon="mdi-pencil"
            variant="outlined"
            :rules="notEmptyRule"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("email") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="email"
            density="compact"
            readonly
            :placeholder="$t('email')"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
            style="min-width: 300px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("phoneNumber") }}
          </div>
          <v-text-field
            class="ml-2"
            v-model="phoneNumber"
            density="compact"
            :placeholder="$t('phoneNumber')"
            prepend-inner-icon="mdi-cellphone"
            variant="outlined"
            style="min-width: 300px"
          ></v-text-field>
        </div>
        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t("hospital.department") }}
          </div>

          <v-autocomplete
            class="ml-2"
            v-model="chosenDepartment"
            density="compact"
            :placeholder="$t('hospital.department')"
            :items="departments"
            :item-title="getDepartmentName"
            :item-value="getDepartmentValue"
            prepend-inner-icon="mdi-hospital-building"
            variant="outlined"
            :menu-props="{ width: '300px' }"
          ></v-autocomplete>
        </div>
      </div>
    </v-col>
  </div>

  <h3 align="center" class="mt-5">
    {{ $t("doctor.choose-time") }}
  </h3>
  <v-divider class="mt-4"></v-divider>

  <div align="center">
    <v-card max-width="500" class="mt-10 mb-10" style="border-radius: 2%">
      <div style="display: flex; justify-content: center">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-switch
              v-bind="props"
              color="primary"
              v-model="activeSearch"
              :label="$t('doctor.calender')"
            ></v-switch>
          </template>
          {{ $t("doctor.calender-tip") }}
        </v-tooltip>
      </div>

      <div align="center">
        <div class="mb-10" v-if="activeSearch">
          <div
            class="mt-5 mb-5"
            v-for="(item, index) in manualTime"
            :key="index"
          >
            <v-row style="justify-content: space-evenly">
              <VueDatePicker
                v-model="manualTime[index][0]"
                range
                :enable-time-picker="false"
                :min-date="Date.now()"
                :teleport="true"
                style="width: 60%"
                :clearable="false"
                format="dd MMM yyyy"
              />
              <div style="width: 70px">
                <v-text-field
                  density="compact"
                  v-model="manualTime[index][1]"
                  hide-details
                  single-line
                  type="number"
                  :rules="minRule"
                />
              </div>
              <v-btn
                class="mt-2"
                density="compact"
                color="red"
                icon="mdi-trash-can-outline"
                @click="
                  manualTime = manualTime
                    .slice(0, index)
                    .concat(manualTime.slice(index + 1))
                "
              ></v-btn>
            </v-row>
          </div>
          <v-btn
            density="compact"
            color="primary"
            icon="mdi-plus"
            :rules="minRule"
            @click="manualTime.push([[], 1])"
          ></v-btn>
        </div>
      </div>
    </v-card>
    <v-footer
      style="
        display: flex;
        width: 96%;
        justify-content: end;
        background-color: #f2f2f2;
      "
    >
      <div>
        <div>
          <v-btn
            class="mr-10"
            rounded="lg"
            color="#3fb984"
            variant="flat"
            size="large"
            width="150"
            :loading="isSaving"
            :disabled="infoCompleted"
            @click="saveProfile()"
          >
            {{ $t("save") }}
          </v-btn>
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue"

import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"
import {
  ApplicantTime,
  Department,
  DoctorProfile,
  HospitalDoctor,
} from "@/types"
import { hospitalActions } from "@/stores/hospital-actions"
import PhotoUpload from "@/components/Static/PhotoUpload/PhotoUpload.vue"
import { doctorTitle } from "@/types/spec"
import { doctorActions } from "@/stores/doctor-actions"
import { useToast } from "vue-toast-notification"

export default defineComponent({
  components: { PhotoUpload },
  name: "HospitalDoctorProfile",

  setup(props) {
    const { t } = useI18n()

    const hospitalAction = hospitalActions()
    const route = useRoute()
    const requestResponse = ref()
    const doctor = ref<HospitalDoctor>()
    const loading = ref(true)
    const id = ref()
    const title = ref<string | undefined>(undefined)
    const firstName = ref<string | undefined>(undefined)
    const lastName = ref<string | undefined>(undefined)
    const email = ref<string | undefined>(undefined)
    const phoneNumber = ref<string | undefined>(undefined)
    const photo = ref<string | undefined>(undefined)
    const departments = ref<Department[]>([])
    const chosenDepartment = ref<string | null>(null)
    const locale = ref(localStorage.getItem("locale") || "de")
    const smallWindow = ref(window.innerWidth < 1000)
    const lectureFreeSearch = ref(false)
    const activeSearch = ref(false)
    const manualTime = ref<any[][]>([])
    const applicants = ref<ApplicantTime[]>([])
    const isSaving = ref(false)
    onMounted(async () => {
      id.value = route.params.id
      await getDoctorProfile()
      await getDepartments()
      window.addEventListener("resize", updateWindowSize)
      window.addEventListener("locale-changed", updateLocale)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
      window.removeEventListener("locale-changed", updateLocale)
    })

    const getDoctorProfile = async () => {
      loading.value = true

      const response = await hospitalAction.getDoctor(id.value)
      requestResponse.value = response
      if (response.success) {
        doctor.value = response.result as HospitalDoctor
        title.value = doctor.value?.title ?? "-"
        firstName.value = doctor.value?.firstName ?? undefined
        lastName.value = doctor.value?.lastName ?? undefined
        email.value = doctor.value?.email ?? undefined
        photo.value = doctor.value?.photo
        phoneNumber.value = doctor.value?.privatePhone
        activeSearch.value = doctor.value?.useCalendarSearch ?? false
        applicants.value = doctor.value?.availableDates ?? []
      }
      buildTimeArray(applicants.value)
      loading.value = false
    }

    const buildTimeArray = (array: ApplicantTime[]) => {
      array.forEach((item: ApplicantTime) => {
        const startDate = new Date(item.start)
        const endDate = new Date(item.end)
        manualTime.value.push([[startDate, endDate], item.applicants])
      })
    }

    const notEmptyRule = [(v: string) => !!v || t("field-required")]

    const getDepartmentName = computed(
      () =>
        (department: Department): string => {
          return locale.value == "de" ? department.de : department.en
        }
    )

    const minRule = [
      (v: number) => !!v || t("required"),
      (v: number) => v > 0 || t("min-student-required"),
    ]

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    const updateLocale = () => {
      locale.value = localStorage.getItem("locale") ?? "de"
    }
    const getDepartmentValue = (department: Department): string => {
      return department.value
    }

    const getDepartments = async () => {
      const response = await hospitalAction.getDepartments()
      requestResponse.value = response
      if (response.success) {
        departments.value = response.result as Department[]
      }
    }

    const infoCompleted = computed((): boolean => {
      return (
        activeSearch.value &&
        (manualTime.value[manualTime.value.length - 1]?.[0].length == 0 ||
          manualTime.value[manualTime.value.length - 1]?.[0]?.[0] == null ||
          manualTime.value[manualTime.value.length - 1]?.[0]?.[1] == null ||
          manualTime.value[manualTime.value.length - 1]?.[1] < 1)
      )
    })

    return {
      infoCompleted,
      doctor,
      loading,
      requestResponse,
      id,
      title,
      firstName,
      lastName,
      email,
      photo,
      phoneNumber,
      notEmptyRule,
      doctorTitle,
      departments,
      chosenDepartment,
      getDepartmentName,
      getDepartmentValue,
      activeSearch,
      manualTime,
      minRule,
      hospitalAction,
      t,
      isSaving,
    }
  },
  methods: {
    async saveProfile(): Promise<void> {
      this.isSaving = true
      var applicants: ApplicantTime[] = []

      const docAction = doctorActions()
      const $toast = useToast()

      this.manualTime.forEach((val) => {
        const data: ApplicantTime = {
          start: docAction.getDatesInString(val[0][0] as Date),
          end: docAction.getDatesInString(val[0][1] as Date),
          applicants: parseInt(val[1].toString()),
        }
        applicants.push(data)
      })
      const profile = {
        firstName: this.firstName,
        lastName: this.lastName,
        title: this.title,
        photo: this.photo,
        phoneNumber: this.phoneNumber,
        availableDates: applicants,
        department: this.chosenDepartment,
      }

      const response = await this.hospitalAction.saveDoctorProfile(
        this.id,
        profile
      )
      this.requestResponse = response
      this.isSaving = false
      if (response.success) {
        $toast.success(this.t("profile-saved-successfully"), {
          position: "bottom-left",
        })
      } else {
        $toast.error(response.message ?? "", {
          position: "bottom-left",
        })
      }
    },
  },
})
</script>

<style>
.text {
  font-size: 50px;
  font-weight: 500;
  margin-top: 130px;
}
.sub-text {
  font-size: 20px;
  font-weight: 300;
}

.content {
  overflow-x: hidden;
}
.footer {
  margin-top: 200px;
}
</style>
