import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-459301c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_2 = {
  key: 0,
  class: "mb-10 mt-2"
}
const _hoisted_3 = { class: "text-h5 font-weight-bold mb-4" }
const _hoisted_4 = {
  align: "end",
  class: "mt-3"
}
const _hoisted_5 = { class: "flex flex-wrap" }
const _hoisted_6 = {
  class: "flex-item",
  justify: "start"
}
const _hoisted_7 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_8 = {
  class: "flex-item",
  justify: "start"
}
const _hoisted_9 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_10 = { class: "flex-item" }
const _hoisted_11 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_12 = { class: "flex-item" }
const _hoisted_13 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_14 = { class: "flex-item ml-2" }
const _hoisted_15 = { class: "d-flex text-subtitle-1 text-medium-emphasis" }
const _hoisted_16 = { class: "flex-item" }
const _hoisted_17 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_18 = { class: "flex-item" }
const _hoisted_19 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_20 = { class: "flex-item" }
const _hoisted_21 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_22 = { class: "flex-item" }
const _hoisted_23 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_24 = { class: "flex-item" }
const _hoisted_25 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_26 = { class: "flex-item" }
const _hoisted_27 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_28 = { class: "flex-item" }
const _hoisted_29 = { class: "d-flex text-subtitle-1 text-medium-emphasis ml-2" }
const _hoisted_30 = {
  align: "center",
  justify: "space-between",
  class: "flex mb-10"
}
const _hoisted_31 = { class: "flex-item" }
const _hoisted_32 = { class: "mt-5" }
const _hoisted_33 = { class: "flex-item" }
const _hoisted_34 = { class: "mt-5" }
const _hoisted_35 = {
  key: 0,
  class: "flex-item"
}
const _hoisted_36 = { class: "mt-5" }
const _hoisted_37 = {
  key: 1,
  class: "flex-item"
}
const _hoisted_38 = { class: "mt-5" }
const _hoisted_39 = {
  key: 2,
  class: "flex-item"
}
const _hoisted_40 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_request_status = _resolveComponent("request-status")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_cropper = _resolveComponent("cropper")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isApplying)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.$t("general-info")), 1))
      : _createCommentVNode("", true),
    (!_ctx.isApplying)
      ? (_openBlock(), _createBlock(_component_v_divider, {
          key: 1,
          class: "mt-3"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_request_status, {
      response: _ctx.linkResponse,
      isLoading: _ctx.isLoading
    }, null, 8, ["response", "isLoading"]),
    _createElementVNode("div", null, [
      (!_ctx.isApplying && _ctx.student)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_v_tooltip, { location: "bottom" }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_img, _mergeProps(props, {
                    width: 163,
                    style: {"border-radius":"50%"},
                    cover: "",
                    src: 
                _ctx.photoUrl !== ''
                  ? _ctx.photoUrl
                  : require('../../../assets/blank.png')
              ,
                    onClick: _ctx.onImageButtonClick
                  }), null, 16, ["src", "onClick"]),
                  _createElementVNode("input", {
                    ref: "uploader",
                    class: "d-none",
                    type: "file",
                    accept: "image/*",
                    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageUpload && _ctx.onImageUpload(...args)))
                  }, null, 544)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("image-upload")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_v_dialog, {
                modelValue: _ctx.showCropper,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showCropper) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_sheet, {
                    border: "md",
                    class: "pa-6 text-black mx-auto",
                    width: _ctx.smallWindow ? 320 : 600,
                    color: "white"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", _hoisted_3, [
                        _createVNode(_component_cropper, {
                          class: "cropper",
                          src: _ctx.localImageUrl,
                          "stencil-props": {
                  aspectRatio: 10 / 11,
                },
                          onChange: _ctx.change
                        }, null, 8, ["src", "onChange"]),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_v_btn, {
                            color: "error",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCropper = false))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("go-back")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_btn, {
                            class: "ml-2",
                            color: "green",
                            onClick: _ctx.saveImage
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["width"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("gender")), 1),
          _createVNode(_component_v_select, {
            class: "ml-2",
            modelValue: _ctx.gender,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.gender) = $event)),
            density: "compact",
            placeholder: _ctx.$t('gender'),
            "prepend-inner-icon": "mdi-pencil",
            items: _ctx.genders,
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder", "items"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("first-name")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.firstName,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.firstName) = $event)),
            density: "compact",
            placeholder: _ctx.$t('first-name'),
            "prepend-inner-icon": "mdi-pencil",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("last-name")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.lastName,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.lastName) = $event)),
            density: "compact",
            placeholder: _ctx.$t('last-name'),
            "prepend-inner-icon": "mdi-pencil",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("email")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.email) = $event)),
            density: "compact",
            readonly: "",
            placeholder: _ctx.$t('email'),
            "prepend-inner-icon": "mdi-email-outline",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("birthday")), 1),
          _createVNode(_component_VueDatePicker, {
            scrollable: "",
            modelValue: _ctx.dateOfBirth,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dateOfBirth) = $event)),
            color: "primary",
            "enable-time-picker": false,
            "max-date": _ctx.today,
            clearable: false,
            format: "dd MMM yyyy",
            class: "v-theme--light mb-2",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "max-date"])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("phoneNumber")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.phoneNumber,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.phoneNumber) = $event)),
            density: "compact",
            placeholder: _ctx.$t('phoneNumber'),
            "prepend-inner-icon": "mdi-cellphone",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("street")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.street,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.street) = $event)),
            density: "compact",
            placeholder: _ctx.$t('street'),
            "prepend-inner-icon": "mdi-map-marker",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("post")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.postCode,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.postCode) = $event)),
            density: "compact",
            placeholder: _ctx.$t('post'),
            "prepend-inner-icon": "mdi-numeric",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("city")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.city,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.city) = $event)),
            density: "compact",
            placeholder: _ctx.$t('city'),
            "prepend-inner-icon": "mdi-city",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("state")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.state,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state) = $event)),
            density: "compact",
            placeholder: _ctx.$t('state'),
            "prepend-inner-icon": "mdi-map-marker",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("country")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.country,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.country) = $event)),
            density: "compact",
            placeholder: _ctx.$t('country'),
            "prepend-inner-icon": "mdi-earth",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("university")), 1),
          _createVNode(_component_v_text_field, {
            class: "ml-2",
            modelValue: _ctx.university,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.university) = $event)),
            density: "compact",
            placeholder: _ctx.$t('university'),
            "prepend-inner-icon": "mdi-school",
            variant: "outlined",
            style: {"min-width":"300px"}
          }, null, 8, ["modelValue", "placeholder"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_30, [
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("div", _hoisted_32, [
          _createVNode(_component_v_btn, {
            rounded: "lg",
            color: _ctx.cvUrl ? 'primary' : 'red',
            variant: "flat",
            size: "large",
            width: "180",
            "prepend-icon": "mdi-folder",
            href: _ctx.cvUrl ?? '',
            target: "_blank",
            class: "mb-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("resume")), 1)
            ]),
            _: 1
          }, 8, ["color", "href"]),
          _createVNode(_component_v_tooltip, { location: "bottom" }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_btn, _mergeProps(props, {
                icon: "mdi-folder",
                color: "primary",
                class: "ml-5 mb-2",
                loading: _ctx.isCVSelecting,
                onClick: _ctx.onCVButtonClick
              }), null, 16, ["loading", "onClick"]),
              _createElementVNode("input", {
                ref: "uploaderCV",
                class: "d-none",
                type: "file",
                accept: "application/pdf",
                onChange: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.onCVUpload && _ctx.onCVUpload(...args)))
              }, null, 544)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.$t("upload")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createVNode(_component_v_tooltip, { location: "bottom" }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_btn, _mergeProps(props, {
                rounded: "lg",
                color: _ctx.ibUrl ? 'primary' : 'red',
                variant: "flat",
                size: "large",
                width: "180",
                "prepend-icon": "mdi-folder",
                href: _ctx.ibUrl ?? '',
                target: "_blank",
                class: "mb-2"
              }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("ib-short")), 1)
                ]),
                _: 2
              }, 1040, ["color", "href"])
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.$t("ib")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { location: "bottom" }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_btn, _mergeProps(props, {
                icon: "mdi-folder",
                color: "primary",
                class: "ml-5 mb-2",
                loading: _ctx.isIBSelecting,
                onClick: _ctx.onIBButtonClick
              }), null, 16, ["loading", "onClick"]),
              _createElementVNode("input", {
                ref: "uploaderIB",
                class: "d-none",
                type: "file",
                accept: "application/pdf",
                onChange: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.onIBUpload && _ctx.onIBUpload(...args)))
              }, null, 544)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.$t("upload")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      (_ctx.isApplying)
        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createVNode(_component_v_tooltip, { location: "bottom" }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps(props, {
                    rounded: "lg",
                    color: _ctx.motivationUrl ? 'primary' : 'red',
                    variant: "flat",
                    size: "large",
                    "prepend-icon": "mdi-folder",
                    href: _ctx.motivationUrl,
                    target: "_blank",
                    class: "mb-2"
                  }), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("motivation")), 1)
                    ]),
                    _: 2
                  }, 1040, ["color", "href"])
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("tip-motivation")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_tooltip, { location: "bottom" }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps(props, {
                    icon: "mdi-folder",
                    color: "primary",
                    class: "ml-5 mb-2",
                    loading: _ctx.isMotivationSelecting,
                    onClick: _ctx.onMLButtonClick
                  }), null, 16, ["loading", "onClick"]),
                  _createElementVNode("input", {
                    ref: "uploaderML",
                    class: "d-none",
                    type: "file",
                    accept: "application/pdf",
                    onChange: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.onMotivationUpload && _ctx.onMotivationUpload(...args)))
                  }, null, 544)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("upload")), 1)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isApplying)
        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("div", null, [
                _createVNode(_component_v_btn, {
                  rounded: "lg",
                  color: "#3fb984",
                  variant: "flat",
                  size: "large",
                  width: "180",
                  loading: _ctx.isSaving,
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.saveProfileData()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("save")), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isApplying)
      ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
          _createVNode(_component_v_dialog, {
            modelValue: _ctx.dialog,
            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.dialog) = $event)),
            "max-width": "400",
            persistent: ""
          }, {
            activator: _withCtx(({ props: activatorProps }) => [
              _createElementVNode("div", _hoisted_40, [
                _createVNode(_component_v_col, { cols: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, _mergeProps({ size: "large" }, activatorProps, {
                      color: "#3fb984",
                      loading: _ctx.dialog,
                      disabled: 
                _ctx.hasAlreadyApplied || !_ctx.isDoctorAvailable || !_ctx.infoCompleted
              ,
                      onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.dialog = true))
                    }), {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("apply")), 1)
                      ]),
                      _: 2
                    }, 1040, ["loading", "disabled"])
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                "prepend-icon": "mdi-rocket-launch",
                title: _ctx.$t('apply-title')
              }, {
                actions: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.apply(false)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("go-back")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.apply(true)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("apply")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}